// hooks/useUserId.js
import { useState, useEffect } from 'react';

const useUserId = () => {
  const [UserId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(localStorage.getItem('userId'));
    };

    // Listen for storage changes in case userId is updated in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return UserId;
};

export default useUserId;
