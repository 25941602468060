import React from 'react';
import { DataUsage } from '@mui/icons-material'; // Assuming you're using MUI icons

function FeatureCards() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 px-4 md:px-10 py-8">
      {/* Card 1 */}
      <div className="bg-[#491aeb] text-white p-[36px] rounded-3xl shadow-lg transform md:-translate-y-4 flex flex-col ">
        <DataUsage fontSize='large' className="text-white mb-4" /> {/* Icon */}
        <h2 className="text-2xl font-bold mb-4">Setting the difficulty level of the test</h2>
        <p className="text-md font-medium">
          Choose easy, medium, or tricky questions from our skill libraries to assess candidates of different experience levels.
        </p>
      </div>

      {/* Card 2 */}
      <div className="bg-[#f4f3fa] text-gray-900 p-[36px] rounded-3xl shadow-lg transform md:translate-y-4 flex flex-col">
        <DataUsage fontSize='large' className="text-gray-900 mb-4" /> {/* Icon */}
        <h2 className="text-2xl font-bold mb-4">Combining multiple skills into one test</h2>
        <p className="text-md font-medium">
          Add multiple skills in a single test to create an effective assessment and assess multiple skills together.
        </p>
      </div>

      {/* Card 3 */}
      <div className="bg-[#fd5b0e] text-white p-[36px] rounded-3xl shadow-lg transform md:-translate-y-4 flex flex-col">
        <DataUsage fontSize='large' className="text-white mb-4" /> {/* Icon */}
        <h2 className="text-2xl font-bold mb-4">Adding your own questions to the test</h2>
        <p className="text-md font-medium">
          Add, edit, or bulk upload your coding, MCQ, and whiteboard questions.
        </p>
      </div>

      {/* Card 4 */}
      <div className="bg-[#fcd1bd] text-gray-900 p-6 rounded-3xl shadow-lg transform md:translate-y-4 flex flex-col">
        <DataUsage fontSize='large' className="text-gray-900 mb-4" /> {/* Icon */}
        <h2 className="text-2xl font-bold mb-4">Requesting a tailor-made test</h2>
        <p className="text-md font-medium">
          Receive a tailored assessment created by our subject matter experts to ensure adequate screening.
        </p>
      </div>
    </div>
  );
}

export default FeatureCards;
