import React from 'react';

const DifficultyBar = ({ values }) => {
    const { easy = 0, normal = 0, hard = 0 } = values ? JSON.parse(values) : {};
    const total = easy + normal + hard;

    return (
        <div className="progress-bar-container w-auto lg:w-440 md:w-40 rounded-sm" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="flex justify-between mb-1">
                <span className='text-sm' title='easy'>{easy}%</span>
                <span className='text-sm' title='normal'>{normal}%</span>
                <span className='text-sm' title='hard'>{hard}%</span>
            </div>
            <div className="flex" style={{ height: '4px', overflow: 'hidden', borderRadius: '5px', background: '#e0e0e0' }}>
                <div
                    title='easy'
                    style={{
                        width: `${(easy / total) * 100}%`,
                        backgroundColor: '#4caf50',
                    }}
                />
                <div
                    title='normal'
                    style={{
                        width: `${(normal / total) * 100}%`,
                        backgroundColor: '#ffeb3b',
                    }}
                />
                <div
                    title='hard'
                    style={{
                        width: `${(hard / total) * 100}%`,
                        backgroundColor: '#f44336',
                    }}
                />
            </div>
        </div>
    );
};

export default DifficultyBar;
