import React, { useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { useNavigate } from 'react-router-dom';
import ChannelPreview from '../Channel/ChannelPreview';
import { CustomButton, EditIconButton, LikeButton } from '../../ButtonsIconsInputs/IconButtons';
import GenericSeriesCard from '../Channel/GenericSeriesCard';
import GenericAssessmentCard from '../Channel/GenericAssessmentCard';
import GenericChannelCard from '../Channel/GenericChannelCard';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import Heading from '../reusableComp/Heading';
import useUserId from '../../services/useUserId';


function Mysubscriptions() {
    const [ChannelMasterData, setMasterData] = useState([])
    const [error, setError] = useState(null);
    const UserId=useUserId()

    const [filteredData, setfilteredData] = useState([]);
    const [query, setQuery] = useState('');

    const navigate = useNavigate();

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/MySubscriptions`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: UserId })
            });
            const response = await result.json();
            setMasterData(response.data);
            setfilteredData(response.data)

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);

    const handleSearch = (e) => {

        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(ChannelMasterData);
        } else {
            const result = ChannelMasterData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            setfilteredData(result);
        }
    }

    
    const MyActions = [
       
        
        {
            label: 'like',
            handler: () => { },
            tooltip: 'like',
            ButtonComponent: LikeButton,

        }

    ];


    return (
        <div>
{ <div>
    <div className='text-center mt-5'><Heading heading='My Subscriptions' />  <p className='text-gray-500 text-sm -mt-5'>(below contents are either subscribed by you or assigned to you)</p></div>
                <div className="flex flex-row justify-start p-2  py-4  lg:w-1/5 md:2/5 ">
                    <InputBox
                        inputId='searchChannel'
                        inputLabel="Search Channels"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {filteredData.map((item, index) => {
                        let badgeColor = "";
                        let borderColor = "";

                        // Define badge and border color based on AssignmentType
                        badgeColor = "bg-purple-500";
                        borderColor = "border-purple-300";
                        if (item.AssignmentType == 'channel') {
                            return (
                                    
                                <GenericChannelCard
                                    title={item.ChannelName}
                                    subtitle={item.CreaterName}
                                    imageSrc={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
                                    badgeText="Channel"
                                    badgeColor={badgeColor}
                                    actions={MyActions}
                                    item={item}
                                />
                            );
                        }
                        else if (item.AssignmentType == 'assessment') {
                            return (
                                < GenericAssessmentCard
                                    item={item}
                                    ShowSubscribeButton={true}
                                    actions={MyActions}

                                />)
                        }
                        else {

                            return (
                                <GenericSeriesCard
                                    item={item}
                                    ViewAssessmentOfSeriesData={true}
                                    actions={MyActions}
                                />
                            )

                        }

                    })}


                </div>
            </div>}
          
        </div>
    );


}

export default Mysubscriptions