import React from "react";
import { Divider } from "@mui/material";
import { CloseIconButton } from "../../ButtonsIconsInputs/IconButtons";
import { type } from "@testing-library/user-event/dist/type";

function ImageUpload({ images, onRemove }) {
  return (
    <div className="flex  flex-wrap gap-4">
      {images.map((image, index) => (
        <div key={index} className="relative ">
          <div className="flex ">
            {typeof image == "string" ? (
              <img
                src={image}
                alt={`uploaded ${index}`}
                className="w-32 h-32 object-cover rounded-md"
              />
            ) : (
              <img
                src={URL.createObjectURL(image)}
                alt={`uploaded ${index}`}
                className="w-32 h-32 object-cover rounded-md"
              />
            )}{" "}
            <CloseIconButton
              action={() => onRemove(index)}
              iconColor="text-gray-700 h-10"
            />
          </div>
        </div>
      ))}
      <Divider orientation="vertical" />
    </div>
  );
}

export default ImageUpload;
