import React, { useEffect, useState } from 'react'

import './App.css'
import { useNavigate } from 'react-router-dom';
import StanderdConstants from '../../services/StanderdConstants';
import { AddIconButton, EditIconButton, InviteButton } from '../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import CreateAssessmentSeries from './AddChannel';
import AddModal from '../allocations/AddModal';
import AddChannelModal from './AddChannel';
import DataTable from './DataTable';
import EditChannelModal from './EditChannelModal';
import { Escalator } from '@mui/icons-material';
import useUserId from '../../services/useUserId';


function Chennel() {
    const [Data, SetData] = useState([]);
    const [open, setopen] = useState(false);
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentDatarow, setcurrentDatarow] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
    const [query, setQuery] = useState('');
    const [Loading,setLoading]=useState(false)

    const UserId = useUserId();
   

    useEffect(() => {
        if (insertionStatus) {
            getChannels()
            setInsertionStatus(false);
        }
    }, [insertionStatus, Data]);


    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); }
    const handleEditOpen = (Data, index) => {
        setcurrentDatarow(Data); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentDatarow(null); console.log("called") }

    const handleDelete = async (item) => {
        let channelId = Object.values(item)[0]; // Assuming the first value is the channelId

        console.log(item);

        const res = await fetch(`${api_url}/Channel/Delete/?ChannelId=${channelId}`, { // Correctly pass the ID in the URL path
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });

        if (res.ok) {
            setInsertionStatus(true); // Trigger success action
        } else {
            console.log('Error occurred while deleting');
        }
    };







    const AssessmentActions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: handleEditOpen,
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation,

        },
        {
            label:'Invite',
            handler:(item)=>{navigate('/InviteCandidate',{state:{content:item}})},
            ButtonComponent:InviteButton,
            tooltip:'Invite Candidates',
            // requiredPermission:permissionsNameForThisPage.Invite

        }

    ];




    const getChannels = async () => {
        setLoading(true)
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/getChannels`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId:UserId })

            });
            const response = await result.json();
            if (result.ok) {
                SetData(response.data)
                setfilteredData(response.data)
                setLoading(false)
            }
            else{
                setLoading(false)
            }


        } catch (error) {
            setLoading(false)
            console.log(error.message);
        }
    };

    useEffect(() => {
        getChannels();
    }, []);



    useEffect(() => {
        console.log("change")
        if (editStatus) {
            getChannels()
            setEditStatus(false)
        }
    }, [editStatus]);

    const columns = [
        { header: StanderdConstants.SERIES_HEADER_NAME, field: StanderdConstants.ASSESSMENTSERIESTABLE_COLOUMN_SERIESNAME },
        { header: StanderdConstants.ASSESSMENT_TABLEHEADER, field: StanderdConstants.ASSESSMENT_TABLE_COLUMN_ASSESSMENTNAME }
    ];

    const handleSearch = (e) => {
        console.log(query);
        console.log(e.target.value)
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(Data);
        } else {
            console.log("here")
            const result = Data.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            console.log(result)
            setfilteredData(result);
        }
    }

    return (
        <div className="container mx-auto py-8 px-2">
            <Heading heading={"Manage Channels"} />
            <div className="flex flex-row  justify-between space-x-2 my-2 ">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchChannel'
                        inputLabel="Search Channel"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {<div>
                    <AddIconButton action={() => handleopen(true)} id="addChannel" tooltip='Add new role' />
                    {open && <AddChannelModal handleClose={handleclose} setStatus={setInsertionStatus} />}

                </div>}

            </div>
{    !Loading ? (<DataTable data={filteredData} actions={AssessmentActions} />):(<div className='text-center'>...Loading</div>)
}



            {editOpen && (
                <EditChannelModal
                    channelData={currentDatarow}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus} />
            )}
        </div>

    )

}

export default Chennel