import React, { useEffect, useState } from 'react';
import StanderdConstants from '../../services/StanderdConstants';
import '../Channel/App.css'
import useUserId from '../../services/useUserId';
export default function Subscription({ userId = StanderdConstants.UserId, Type, Id, SubscriptionPlanTypeId, SubscriptionAmount,SubscriptionStatus,ButtonColor }) {
    const [loading, setLoading] = useState(false); // Track the loading state
    const [subscribed, setSubscribed] = useState(false); // Track if the user has subscribed
    const [error, setError] = useState(null); // Track any errors
const UserId=useUserId()
    useEffect(()=>{
        console.log(SubscriptionStatus)
if(SubscriptionStatus){
    setSubscribed(true)
}

    },[SubscriptionStatus])

    // Subscription handler function
    const handleSubscription = async () => {
        setLoading(true);
        setError(null);

        try {
            // Call the subscription API using fetch
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Subscription/Subscribe/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId:UserId,
                    Type, // 'assessment', 'series', or 'channel'
                    Id, // AssessmentId, SeriesId, or ChannelId
                    SubscriptionPlanTypeId,
                    SubscriptionAmount
                }),
            });

            // Handle the response
            if (!response.ok) {
                throw new Error(`Failed to subscribe. Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Subscription successful:', result);

            // Update the state to show that the user has subscribed
            setSubscribed(true);
        } catch (error) {
            console.error('Error during subscription:', error);
            setError('Failed to subscribe, please try again.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Dynamically determine the button text based on state
    // const getButtonText = () => {
    //     if (subscribed) return 'Subscribed';
    //     if (SubscriptionAmount === 0) return 'Subscribe';
    //     return `Subscribe for $${SubscriptionAmount}`;
    // };

    return (
        <div className='flex flex-row items-center'>
            {/* Display error message if exists */}
            {error && <div className="text-red-500  mb-2">{error}</div>}

            {/* Subscription button */}
            <button
                onClick={handleSubscription}
                className={`lss-buttons text-sm bg-sky-600 text-gray-50 
    ${loading && 'bg-gray-400 cursor-not-allowed' }
  `}
                disabled={loading}
            >
                {loading ? 'Processing...' : subscribed ? 'Subscribed' : 'Subscribe'}
            </button>


        </div>
    );
}
