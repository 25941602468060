import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Chip } from '@mui/material';
import DifficultyBar from './DifficultyBar';
import StanderdConstants from '../../services/StanderdConstants';

const TableComp = ({ data = [], columns, actions, tableName, IsSuperAdmin, CompanyId,Showpagination=true }) => {
    let rowsPerPage = 10
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = data?.slice(startIndex, endIndex);

    useEffect(() => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages); // Move to the last valid page
        } else if (data.length === 0) {
            setPage(1); // Reset to page 1 if no data
        }
    }, [data, page, rowsPerPage]);

    console.log(actions)

    let isreservedcheck = columns.some(col => col.field == StanderdConstants.COLUMN_DBTABLE_IS_RESERVED)
    console.log(IsSuperAdmin)

    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border-2  border-gray-200">
                        <thead>
                            <tr className="text-gray-50" style={{ backgroundColor: '#6495ED' }}>
                                {columns?.map((col, index) => (
                                    (col?.field !== 'isReserved') && (
                                        <th key={index} className="border p-2 text-left ">
                                            {col.header}
                                        </th>
                                    )
                                ))}
                                {actions?.length > 0 && <th className="border p-2 w-fit">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    {isreservedcheck ? (
                                        <>   {
                                            Object.keys(item).includes('isReserved') && item.isReserved == 1 && (
                                                <td className="border p-2">

                                                    {item[columns[0].field]} <Chip label='Reserved' className='ml-2' color='primary' /> {/* Display the Chip */}
                                                </td>)
                                        }
                                            {columns.map((col, colIndex) => (
                                                <React.Fragment key={colIndex}>

                                                    {(col.field !== 'isReserved' && colIndex > 1) && (

                                                        <td className="border p-2">
                                                            {
                                                                col.field == 'Status' ? (
                                                                    <>
                                                                        {
                                                                            item[col.field] == 'Active' ?
                                                                                (
                                                                                    <>

                                                                                        <Chip label='Active' color='success' />

                                                                                    </>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <>

                                                                                        <Chip label='Inactive' color='default' />

                                                                                    </>
                                                                                )
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>


                                                                        {col.field == 'difficultyLevel' ? (
                                                                            <DifficultyBar values={item[col.field]} />
                                                                        ) : (<> {item[col.field]}</>)}


                                                                    </>


                                                                )
                                                            }


                                                        </td>
                                                    )
                                                    }
                                                </React.Fragment>
                                            ))}</>
                                    ) : (<>
                                        {columns.map((col, colIndex) => (
                                            <React.Fragment key={colIndex}>


                                                <td className="border p-2">
                                                    {
                                                        col.field == 'Status' ? (
                                                            <>
                                                                {
                                                                    item[col.field] == 'Active' ?
                                                                        (
                                                                            <>


                                                                                <Chip label='Active' color='success' />

                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>

                                                                                <Chip label='Inactive' color='default' />

                                                                            </>
                                                                        )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>

                                                                {col.field == 'difficultyLevel' ? (
                                                                    <DifficultyBar values={item[col.field]} />
                                                                ) : (<> {item[col.field]}</>)}



                                                            </>


                                                        )
                                                    }


                                                </td>


                                            </React.Fragment>
                                        ))}
                                    </>
                                    )
                                    }
                                    {actions.length > 0 && (
                                        <td className="border p-2 w-24">
                                            {(item.UserId == StanderdConstants.UserId || CompanyId == item.CompanyId || IsSuperAdmin) ? (
                                                <div className="flex justify-around">
                                                    {actions.map((action, actionIndex) => {
                                                        const ButtonComponent = action.ButtonComponent;
                                                        return (
                                                            <ButtonComponent
                                                                key={actionIndex}
                                                                action={() => action.handler(item, index)}
                                                                tooltip={action.tooltip}
                                                                id={index}
                                                                CompanyId={CompanyId}
                                                                itemName={Object.values(item)[1]}
                                                                itemData={item}
                                                                label={action.label}

                                                                className={action.className}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <span className='flex justify-center'>  <Chip label='reserved' color='primary' /></span>

                                            )}
                                        </td>

                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>



                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>  
            )}
        { Showpagination && <div className="flex justify-center mt-4">
            <Stack spacing={2}>
                <Pagination
                    count={Math.ceil(data.length / rowsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                />
            </Stack>
        </div>}
        </div>
    );
};

TableComp.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
    })).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
        tooltip: PropTypes.string,
        className: PropTypes.string,
        ButtonComponent: PropTypes.elementType.isRequired, // Custom button component
    })).isRequired,
};



export default TableComp;
