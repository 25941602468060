import React, { useState, useEffect } from 'react';
import './App.css';

import { Button, TextField } from '@mui/material';
import { CloseIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../../reusableComp/PermissionCheck';
import StanderdConstants from '../../../services/StanderdConstants';
import { useToast } from '../../../services/useToast';
import Toast from '../../reusableComp/Toast';

function EditModal({ pageHeading, data, setStatus, setRecentformData, EditParam, tableName }) {
    const [Message, setMessage] = useState('');
    const [Data, setData] = useState([]);
    const [userTypeData, setUserTypeData] = useState([]);
    const [StatusData, setStatusData] = useState([])
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    const [formValues, setFormValues] = useState({})
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   const {hasPermission}=usePermissions()
    const api_url = process.env.REACT_APP_API_URL;
    useEffect(() => {
        if (open) {
            console.log("setting data")
            setData(data);
            setFormValues(data)
            console.log(Data)
        }

    }, [data, open]);


    useEffect(() => {
        console.log(formValues)
    }, [formValues])


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            tableName,
            data: formValues,
        };
        await sendDataToServer(payload);
    };




    const sendDataToServer = async (formData) => {
        const res = await fetch(`${api_url}/manage/Edit`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        const result = await res.json();
        setMessage(result.Message);
        showToast(result.message,result.status)



        if (res.ok) {
            setStatus(true);
            setRecentformData(formData);
        } else {
            console.error("Error while updating");
        }
    };

    useEffect(() => {
        if (open) {
            if (EditParam.includes(StanderdConstants.COLUMN_USERTYPEID)) {
                const getUserTypeData = async () => {
                    try {

                        const response = await fetch(`${api_url}/manage/getData`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ tablename: 'staticusertypes' }),
                        });

                        const result = await response.json();
                        if (response.ok) {
                            setUserTypeData(result.data);
                        } else {
                            console.error('Failed to fetch user type data');
                        }
                    } catch (error) {
                        console.error('Error fetching user type data:', error);
                    }
                };
                getUserTypeData();
            }
            if (EditParam.includes(StanderdConstants.COLUMN_STATUS) || EditParam.includes('StatusId')) {
                const getStatusData = async () => {
                    try {

                        const response = await fetch(`${api_url}/manage/getData`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_STAITCSTATUS }),
                        });

                        const result = await response.json();
                        if (response.ok) {
                            setStatusData(result.data);
                        } else {
                            console.error('Failed to fetch user type data');
                        }
                    } catch (error) {
                        console.error('Error fetching user type data:', error);
                    }
                };
                getStatusData();
            }
        }


    }, [EditParam, open]); // Keep this dependency
   

    return (
        <div>
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            {!open && (

                <EditIconButton action={handleOpen} id={Object.values(data)[0]} />
            )}
            {open && (
                <div className='modal-overlay flex justify-center items-center min-h-screen bg-gray-100'>
                    <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md  '>
                        <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                            <div className='invisible'>content</div>
                            <h2 className='text-xl text-center'>Edit {pageHeading}</h2>
                            <div className='self-center'>
                                <CloseIconButton action={handleClose} />
                            </div>
                        </div>
                        <form className='flex flex-col px-5 mb-5 gap-10' onSubmit={handleSubmit}>
                            {EditParam.map((item, index) => (
                                <div key={index} className='flex flex-col'>
                                    {item === 'UserTypeId' ? (

                                        <TextField
                                            name={item}
                                            select
                                            value={formValues[item]}
                                            onChange={handleInputChange}
                                            fullWidth
                                            SelectProps={{ native: true }}
                                            variant='standard'
                                        >
                                            <option value="" disabled>Select User Type</option>
                                            {userTypeData.length > 0 && userTypeData.map((option) => (
                                                <option key={option.UserTypeId} value={option.UserTypeId}>
                                                    {option.UserType}
                                                </option>
                                            ))}
                                        </TextField>

                                    ) : (
                                        <>
                                            {item == 'Status' || item == 'StatusId' ?
                                                (

                                                    <TextField
                                                        name={item}
                                                        select
                                                        value={formValues[item] || ''}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        SelectProps={{ native: true }}
                                                        variant='standard'
                                                    >
                                                        <option value="" disabled>Status</option>
                                                        {StatusData.length > 0 && StatusData.map((option) => (
                                                            <option key={option.StatusId} value={option.StatusId}>
                                                                {option.Status}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                ) :
                                                (




                                                    <>{

                                                        item == 'IsReserved' ?
                                                            (
                                                                hasPermission('Admin-IsReserved') && <TextField
                                                                    name={item}
                                                                    select
                                                                    value={formValues[item] || ''}
                                                                    onChange={handleInputChange}
                                                                    fullWidth
                                                                    SelectProps={{ native: true }}
                                                                    variant='standard'
                                                                >
                                                                    <option value="" disabled>Is Reserved</option>
                                                                    <option key={1} value={1}>
                                                                        Reserved
                                                                    </option>
                                                                    <option key={2} value={2}>
                                                                        Not Reserved
                                                                    </option>


                                                                </TextField>
                                                            ) :
                                                            (
                                                                <InputBox
                                                                    required={true}
                                                                    inputLabel={item}
                                                                    inputId={`add-${item}`}
                                                                    onChange={handleInputChange}
                                                                    inputValue={formValues[item]}
                                                                    type={'text'}
                                                                    inputName={item}
                                                                />

                                                            )


                                                    }</>

                                                )


                                            }

                                        </>


                                    )}
                                </div>
                            ))}
                            {Message && <div id='status' className='text-yellow-300'>{Message}</div>}
                            <Button variant='contained' id='Save' type='submit'>
                                Save
                            </Button>
                        </form>
                                            </div>

                </div>

            )}
        </div>
    );
}

export default EditModal;
