import React from 'react';
import ReactDOM from 'react-dom';
import { CheckCircle, WarningAmber, ErrorOutline, Dangerous, Error } from '@mui/icons-material';
import './toast.css';

const Toast = ({ message, isVisible, type, pauseHideTimer, resumeHideTimer }) => {
  if (!isVisible) return null;

  const getBackgroundColor = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'warning':
        return 'bg-yellow-500';
      case 'error':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getIcon = () => {
    switch (type) { 
      case 'success':
        return <CheckCircle className="text-white mr-2" />;
      case 'warning':
        return <Error className="text-white mr-2" />;
      case 'error':
        return <Dangerous className="text-white mr-2" />;
      default:
        return null;
    }
  };

  const toastRoot = document.getElementById('toast-root');

  return toastRoot
    ? ReactDOM.createPortal(
        <div
          className="toast-container"
        
        >
          <div
            className={`toast text-white px-8 py-4 rounded-lg shadow-lg ${getBackgroundColor()} ${
              isVisible ? 'toast-enter' : 'toast-exit'
            }`}
            onMouseEnter={pauseHideTimer}
            onMouseLeave={() => resumeHideTimer(3000)}
          >
            <span className="flex items-center">
              {getIcon()}
              <span className="font-semibold">{message}</span>
            </span>
          </div>
        </div>,
        toastRoot
      )
    : null;
};

export default Toast;
