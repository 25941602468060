import React, { useEffect, useState } from 'react';
import { InputBox2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './App.css'

// Define validation schema
const validationSchema = Yup.object({
    // CompanyName: Yup.string().required('Company name is required'),
    // CompanyPhoneNumber: Yup.string().required('Phone number is required'),
    // CompanyType: Yup.string().required('Company type is required'),
    // CompanyEmail: Yup.string().email('Invalid email format').required('Email is required'),
    // CompanyLogo: Yup.mixed().required('Company logo is required'), // Validation for file upload
});

function EditModal({ handleClose, setStatus, setRecenteditFormData, existingData }) {
    const [message, setMessage] = useState('');

    const api_url = process.env.REACT_APP_API_URL;

    // Formik setup
    const formik = useFormik({
        initialValues: {
            CompanyName: existingData.CompanyName || '',
            CompanyPhoneNumber: existingData.CompanyPhoneNumber || '',
            CompanyType: existingData.CompanyType || '',
            CompanyEmail: existingData.CompanyEmail || '',
            CompanyLogo: null, // Reset file input
        },
        validationSchema,
        onSubmit: values => {
            console.log(values);

            const formData = new FormData();
            formData.append('CompanyName', values.CompanyName);
            formData.append('CompanyPhoneNumber', values.CompanyPhoneNumber);
            formData.append('CompanyType', values.CompanyType);
            formData.append('CompanyEmail', values.CompanyEmail);
            formData.append('CompanyLogo', values.CompanyLogo);

            // Send the form data to the server
            sendDataToServer(formData);
        }
    });

    const errors = formik.errors;
    const touched = formik.touched;

    // Custom handleChange for file input
    const handleFileChange = (event) => {
        formik.setFieldValue('CompanyLogo', event.currentTarget.files[0]);
    };

    const sendDataToServer = async (values) => {
        const res = await fetch(`${api_url}/companies/Edit/?CompanyId=${existingData.CompanyId}`, {
            method: 'POST',
            body: values
        });

        const result = await res.json();
        console.log(result);
        setMessage(result.message);
        if (res.ok) {
            console.log("Data updated");
            setStatus(true);
            setRecenteditFormData(true);
        } else {
            console.log("Error while updating");
            setMessage(result.message);
        }
    };
    console.log(`${process.env.REACT_APP_API_URL}/Logo${existingData.CompanyLogo}`)
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                handleClose();  // Trigger the close function when Esc is pressed
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [handleClose]);

    return (
        <div className='modal-overlayyy'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md modal-contentt'>
                <div className='flex justify-between items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4 modal-header'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Company"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                {/* Modal body */}
                <div className='modal-body'>
                    <form className='flex flex-col gap-10 px-5' onSubmit={formik.handleSubmit}>
                        {['CompanyName', 'CompanyPhoneNumber', 'CompanyType', 'CompanyEmail'].map((item) => (
                            <InputBox2
                                key={item}
                                inputLabel={item}
                                inputId={`edit-${item}`}
                                inputName={item}
                                inputValue={formik.values[item]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type={'text'}
                                error={touched[item] && errors[item]}
                                helperText={touched[item] && errors[item]}
                            />
                        ))}

                        <div className="form-group">
                            <label htmlFor="edit-CompanyLogo">Company Logo</label>
                            <input
                                id="edit-CompanyLogo"
                                name="CompanyLogo"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                            {touched.CompanyLogo && errors.CompanyLogo && (
                                <div className="error">{errors.CompanyLogo}</div>
                            )}
                        </div>

                        {!formik.values.CompanyLogo && existingData.CompanyLogo && (
                            <div className=' flex flex-row items-center mx-auto justify-center rounded-sm h-40 w-40'>
                                <img alt='logo' src={`${process.env.REACT_APP_API_URL}/Logo${existingData.CompanyLogo}`} />
                            </div>
                        )}

                        {message && <div>{message}</div>}

                        <Button variant='contained' className='mt-4' id='editCompany' type='submit'>
                            Update
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );

}

export default EditModal;
