import React, { useContext, useEffect, useState } from "react";
import StanderdConstants from "../../services/StanderdConstants";
import { InputBox } from "../../ButtonsIconsInputs/CustomInputs";
import GenericChannelCard from "../Channel/GenericChannelCard";

import GenericAssessmentCard from "../Channel/GenericAssessmentCard";
import GenericSeriesCard from "../Channel/GenericSeriesCard";

import useUserId from "../../services/useUserId";
import { SearchContext } from "../../useContext/SearchContext";
import Invite from "../Invitation/Invite";
import { InviteButton } from "../../ButtonsIconsInputs/IconButtons";
import { useNavigate } from "react-router-dom";
import usePermissions from "../reusableComp/PermissionCheck";
import FeatureCards from "../reusableComp/FeatureCards";

function ExploreChannels() {
  const [error, setError] = useState(null);
  const [filteredData, setfilteredData] = useState([]);
  const [query, setQuery] = useState("");
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const navigate = useNavigate();
  const { filterActionsByPermission, hasPermission } =usePermissions();
  const UserId = useUserId();

  const [Content, SetContent] = useState();

  const getContentData = async (data) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/search-content`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            searchType: "all",
            searchQuery: searchQuery.query,
            UserId,
          }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        // Handle successful response
        console.log(response);
        SetContent([
          ...response.data.assessments,
          ...response.data.series,
          ...response.data.channels,
        ]);
        setfilteredData([
          ...response.data.assessments,
          ...response.data.series,
          ...response.data.channels,
        ]);
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      getContentData();
    }
  }, [searchQuery]);

  useEffect(() => {
    getContentData();
  }, []);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);
    if (!searchQuery) {
      setfilteredData(Content);
    } else {
      const result = Content.filter((row) =>
        Object.values(row) // Get all values from the object (row)
          .some(
            (value) =>
              value
                ?.toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) // Check if any value contains the search query
          )
      );
      setfilteredData(result);
    }
  };

  const MyActions = [
    {
      label: "Invite",
      handler: (item) => {
        navigate("/InviteCandidate", { state: { content: item } });
      },
      tooltip: "Invite",
      ButtonComponent: InviteButton,
      requiredPermission:'Assessment-Invite'
    },
  
  ];
  const AllowedActions = filterActionsByPermission(MyActions)

  return (
    <div className="p-4">
      {
        <div>
          <div className="flex flex-row justify-start p-4 py-4  lg:w-1/5 md:2/5 ">
            {searchQuery.query && (
              <span className="flex flex-row font-medium text-gray-900 items-center whitespace-nowrap">
                Showing result for "{searchQuery.query}"
              </span>
            )}{" "}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {filteredData.map((item, index) => {
              let badgeColor = "";
              let borderColor = "";

              // Define badge and border color based on AssignmentType
              badgeColor = "bg-purple-500";
              borderColor = "border-purple-300";
              if (item.contentType == "channel") {
                return (
                  <GenericChannelCard
                    title={item.ChannelName}
                    subtitle={item.CreaterName}
                    imageSrc={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
                    badgeText="Channel"
                    badgeColor={badgeColor}
                    actions={AllowedActions}
                    item={item}
                    ButtonColor={badgeColor}
                  />
                );
              } else if (item.contentType == "assessment") {
                return (
                  <GenericAssessmentCard
                    item={item}
                    ShowSubscribeButton={true}
                    actions={AllowedActions}
                  />
                );
              } else {
                return (
                  <GenericSeriesCard
                    item={item}
                    ViewAssessmentOfSeriesData={true}
                    actions={AllowedActions}
                  />
                );
              }
            })}
          </div>
        </div>
      }

      
    </div>
  );
}

export default ExploreChannels;
