import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

const usePermissions = () => {
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const fetchPermissions = () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    setPermissions(decodedToken.permissions || []);
                } catch (error) {
                    console.error("Invalid token or decoding error:", error);
                    setPermissions([]);
                }
            } else {
                console.warn("No token found in localStorage");
                setPermissions([]);
            }
        };

        // Initial fetch
        fetchPermissions();

        // Add an event listener to listen for token changes
        window.addEventListener("storage", fetchPermissions);

        // Clean up event listener on component unmount
        return () => window.removeEventListener("storage", fetchPermissions);
    }, []);

    const hasPermission = (requiredPermission) => {
        return permissions.some(permission =>
            permission.trim().toLowerCase() === requiredPermission.trim().toLowerCase()
        );
    };

    const filterActionsByPermission = (actions) => {
        return actions.filter(action =>
            hasPermission(action.requiredPermission)
        );
    };

    return { hasPermission, filterActionsByPermission };
};

export default usePermissions;
