import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, Typography } from '@mui/material';
import StanderdConstants from '../../../services/StanderdConstants';

const timePeriods = [
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' },
];

export function AssessmentTakenByDate() {
    const [selectedPeriod, setSelectedPeriod] = useState('week');
    const [direction, setDirection] = useState('current');
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (period, dir) => {
        setLoading(true);
        setError(null);
    
        try {
            // Fetch data based on selected period and direction
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/OrgDahsboard/assessments`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ period, dir,UserId:StanderdConstants.UserId }),
                }
            );
    
            if (!response.ok) {
                // If the response status is not OK, throw an error
                throw new Error('Failed to fetch data');
            }
    
            const data = await response.json();
            setStats(data);
        } catch (err) {
            setError('Error fetching data');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // Fetch data whenever the selected period or direction changes
        fetchData(selectedPeriod, direction);
    }, [selectedPeriod, direction]);

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        setDirection('current'); // Reset direction when changing period
    };

    const handleNext = () => {
        setDirection('next'); // Set direction to next
    };

    const handlePrevious = () => {
        setDirection('previous'); // Set direction to previous
    };

    return (
        <div className="flex flex-col items-center space-y-6 bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
        <TextField
            id="time-period-select"
            select
            value={selectedPeriod}
            label="Select Time Period"
            onChange={handlePeriodChange}
            variant="standard"
            className="w-full max-w-xs"
            size="small"
            margin="dense"
        >
            {timePeriods.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <div className="flex space-x-4">
            <Button
                variant="contained"
                onClick={handlePrevious}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
            >
                Previous
            </Button>
            <Button
                variant="contained"
                onClick={handleNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
            >
                Next
            </Button>
        </div>
        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {stats && (
            <div className="mt-4 p-4 bg-gray-50 rounded-md shadow-inner">
                <Typography variant="h6" className="font-bold text-lg">Statistics</Typography>
                <Typography>Total Assessments Taken: {stats.totalAssessments}</Typography>
                {stats.averageScore && <Typography>Average Score: {stats.averageScore}</Typography>}
                {stats.otherMetrics && <Typography>Other Metrics: {stats.otherMetrics}</Typography>}
            </div>
        )}
    </div>
    
    );
}
