import React, { useEffect, useState } from 'react';
import { InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs'; // Assuming you're using this for multi-select input
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { Button, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import StanderdConstants from '../../services/StanderdConstants';
import { UserCompanyId } from '../reusableComp/IsOrganisation';
import useUserId from '../../services/useUserId';
import { useToast } from '../../services/useToast';
import Toast from '../reusableComp/Toast';
import { elements } from 'chart.js';

function EditModal({ handleClose, setStatus, Data }) {
    const [CandidateGroupData, setCandidateGroupData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [Message, setMessage] = useState([]);
    const [CompanyEmpolyeeData,setCompanyEmpolyeeData]=useState([])
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();
    const [loading, setLoading] = useState(false);
    const [ShowResultToCandidate, setShowResultToCandidate] = useState(false);


const UserId=useUserId()
    const formik = useFormik({
        initialValues: {
            assessmentId:'',
                SeriesId: '',
                ChannelId:'',
            Type: '',
            usersIdArray: [],
            groupIdArray: [],
            selectedCandidatedata: [],
            ResultShowsTo:[],
        },
        onSubmit: (values) => {
            console.log(ShowResultToCandidate)
            if(ShowResultToCandidate)
                {
                  
                  const UserIdofGroupCandidates = CandidateGroupData.find(
                      item => values.groupIdArray.includes((item.CandidateGroupId))
                  )?.UserIds || "";
                  console.log(UserIdofGroupCandidates)
                  const GroupCandidatesIdArray = UserIdofGroupCandidates
              ? UserIdofGroupCandidates.split(",").map(Number)
              : [];
                 
              values.ResultShowsTo = [...new Set([
                   ...values.ResultShowsTo,
                  ...values.usersIdArray.map(Number), 
                  ...GroupCandidatesIdArray
              ])];        
                  // Log the updated values
                  console.log(values);
                      }
            updateData(values);
        }
    });
    console.log(formik.values)
    // Update formik values based on the Data received
    useEffect(() => {
        if (Data &&  CandidateGroupData) {
            const Idarray = Data?.users?.map(user => user?.Id) || [];
            const Groupidarray = Data?.groups?.map(group => group?.CandidateGroupId) || [];
    
            // Convert resultShowsto string to array of numbers (strArray2)
            const strArray = Data?.resultShowsto?.split(',') || [];
            const strArray2 = strArray.map(item => Number(item.trim())); // Ensure spaces are removed
    
            console.log("strArray2: ", strArray2);
    
            // Check if any values from strArray2 exist in any group in CandidateGroupData
            console.log("CandidateGroupData: ", CandidateGroupData);
    
            const containsUserIdInGroup = CandidateGroupData.some(group => {
                const userIdsArray = group.UserIds.split(',').map(id => Number(id.trim())); // Convert and trim user IDs
            
                return strArray2.some(id => userIdsArray.includes(id) || usersData.some(user => user.Id == id));  // Check if any id in strArray2 matches a UserId in the group
            });
    
            console.log("containsUserIdInGroup: ", containsUserIdInGroup);
    
            if (containsUserIdInGroup) {
                setShowResultToCandidate(true);  // Show result to candidates if any UserId matches
                console.log(true);
            } else {
                setShowResultToCandidate(false);  // Otherwise, hide the result from candidates
                console.log(false);
            }
    
            formik.setValues({
                assessmentId: Data.assessmentId,
                SeriesId: Data.SeriesId,
                ChannelId: Data.ChannelId,
                Type: Data.Type,
                usersIdArray: Idarray,
                groupIdArray: Groupidarray,
                ResultShowsTo: strArray2,
                selectedCandidatedata: [
                    ...Idarray.map(id => `individual-${id}`), // Add prefix for individual IDs
                    ...Groupidarray.map(id => `group-${id}`) // Add prefix for group IDs
                ],
            });
        }
    }, [Data,CandidateGroupData]);
    
   
    const updateData = async (data) => {
        setLoading(true)
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentallocation/Edit`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...data,UserId:UserId})
            }); 
            const response = await result.json();
            showToast(response.message,response.status)
            
            if (result.ok) {
                setLoading(false)
                setMessage(response.Message)
                setStatus(true);
            }
            else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMessage(error.Message);
        }
    };

    const getCandidateGroupData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/CandidateGroup/getGroupData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setCandidateGroupData(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    const getUsers = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_USERS })
            });
            const response = await result.json();
            if (result.ok) {
                setUsersData(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getCandidateGroupData();
        getUsers();
    }, []);

    // Prepare combined candidate list for dropdown
    const combinedCandidateList = [
        ...(Array.isArray(usersData) ? usersData.map(user => ({
            id: `individual-${user.Id}`, // Prefix the ID with 'individual'
            name: user.Name,
            type: 'individual'
        })) : []),
        ...(Array.isArray(CandidateGroupData) ? CandidateGroupData.map(group => ({
            id: `group-${group.CandidateGroupId}`, // Prefix the ID with 'group'
            name: group.CandidateGroupName,
            type: 'group'
        })) : [])
    ];

     // getting list of people of Company , 
     const GetPeopleOfCompany = async () => {
        try {
            const CompanyId = await UserCompanyId(UserId)
            const result = await fetch(`${process.env.REACT_APP_API_URL}/users/GetPeopleOfCompany/?CompanyId=${CompanyId}`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const response = await result.json();
            if (result.ok) {
                setCompanyEmpolyeeData(response.data)
            }

        } catch (error) {
        }
    };

    useEffect(() => {
        GetPeopleOfCompany();
    }, []);


    return (
        <div className='modal-overlayy'>
             <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='opacity-0'>dummy</div>
                    <Heading heading={"Edit Data"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div>
                        <InputWithDropDownv2
                            data={combinedCandidateList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCandidate"
                            inputLabel="Select Candidate"
                            inputName='selectedCandidate'
                            multiple={true}
                            onChange={(event, newValue) => {
                                const selectedIndividualIds = newValue
                                    .filter(item => item.type === 'individual')
                                    .map(item => item.id.replace('individual-', ''));

                                const selectedGroupIds = newValue
                                    .filter(item => item.type === 'group')
                                    .map(item => item.id.replace('group-', ''));
                                formik.setFieldValue('usersIdArray', selectedIndividualIds);
                                formik.setFieldValue('groupIdArray', selectedGroupIds);
                                formik.setFieldValue('selectedCandidatedata', newValue.map(item => item.id)); // Store full ID with prefixes
                            }}
                            inputValue={combinedCandidateList.filter(item =>
                                (formik.values.selectedCandidatedata || []).includes(item.id)
                            ) || []}
                        />
                    </div>
                    
                   
                    <div>
                    <InputWithDropDownv2
                            data={CompanyEmpolyeeData}
                            dataField="Id"
                            dataText="Name"
                            inputId="ResultShowsTo"
                            inputLabel="Result Shows To"
                            inputName='ResultShowsTo'
                            multiple={true}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('ResultShowsTo', newValue.map(item => item.Id)); // Store full ID with prefixes
                            }}
                            inputValue={
                                (CompanyEmpolyeeData && formik.values.ResultShowsTo)
                                    ? CompanyEmpolyeeData.filter(item => formik.values.ResultShowsTo.includes(item.Id))
                                    : []
                            }
                            
                        />
                    </div>
                    <div>
            <Checkbox
              checked={ShowResultToCandidate === true}
              onChange={() => setShowResultToCandidate(!ShowResultToCandidate)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <label htmlFor="contactChoice1">Candidate Can See the Result</label>
          </div>
                   
                    <Button variant='contained' className='mt-4' disabled={loading}  id='addCompany' type='submit'>
                        {loading ? 'Saving...' :'Save'}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditModal;
