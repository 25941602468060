import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox, InputWithDropDownv2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator } from '@mui/icons-material';

function AddModal({ handleClose, setStatus }) {
    const [menuList, setMenuList] = useState([]);
    const [submitting, setsubmitting] = useState(false)
    const [message, setmessage] = useState('')
    const getMenuList = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/allmenus`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            });
            const response = await result.json();
            if (result.ok) {
                setMenuList(response.data);
            }
        } catch (error) {
            console.error('Error fetching menu list:', error);
        }
    };

    useEffect(() => {
        getMenuList();
    }, []);

    const formik = useFormik({
        initialValues: {
            ParentMenuName: '',
            selectedMenus: [],
            IconName: ''
        },
        onSubmit: values => {
            console.log('Submitted Values:', values);
            saveData(values);
        }
    });

    const saveData = async (values) => {
        setsubmitting(true)
        const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/AddGroupMenus`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        });
        const response = await result.json();
        console.log(response)
        if (result.ok) {
            console.log("data saved")
            setmessage(response?.message)
            setsubmitting(false)
        } else {
            setsubmitting(false)
            setmessage(response?.error)

        }
        console.log("Saving Menu Data: ", values);


        setStatus(true);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(formik.values.selectedMenus);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        formik.setFieldValue('selectedMenus', items)
    };



    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Arrange Menus"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    {/* Input for Parent Menu Name */}
                    <div className='w-full'>
                        <InputBox
                            required
                            inputId='parentMenu'
                            inputLabel='Group Name'
                            inputName='ParentMenuName'
                            inputValue={formik.values.ParentMenuName}
                            onChange={formik.handleChange}
                            type='text'
                            customcss='w-full'
                        />
                    </div>

                    <div className='w-full'>
                        <InputBox
                            required
                            inputId='Icon'
                            inputLabel='Group Icon'
                            inputName='IconName'
                            inputValue={formik.values.IconName}
                            onChange={formik.handleChange}
                            type='text'
                            customcss='w-full'
                        />
                    </div>


                    {/* Dropdown for selecting menus */}
                    <div>
                        <InputWithDropDownv2
                            data={menuList}
                            dataField='MenuId'
                            dataText='Menu'
                            inputId='MenuList'
                            inputLabel='Menu'
                            inputName='selectedMenus'
                            multiple
                            onChange={(event, newValue) => {
                                const selectedMenus = newValue.map(item => item.MenuId);
                                formik.setFieldValue('selectedMenus', selectedMenus);
                            }}
                            inputValue={menuList.filter(item => (formik.values.selectedMenus || []).includes(item.MenuId))}
                        />
                    </div>
                    {/* Drag and Drop for Menu Reordering */}
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        {formik.values.selectedMenus && <h4>Arrange Menus</h4>}

                        <Droppable droppableId="menus">
                            {(provided) => (
                                <ul className="menu-list" {...provided.droppableProps} ref={provided.innerRef}>
                                    {formik.values.selectedMenus.map((item, index) => (
                                        <Draggable key={item} draggableId={item.toString()} index={index}>
                                            {(provided) => (
                                                <div className="flex flex-row items-center mb-3"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <li className="bg-white rounded-lg shadow-md p-4 flex justify-between items-center border border-gray-200 w-full">
                                                        <span className="font-semibold text-gray-700">
                                                            {menuList?.find(menu => menu?.MenuId === item)?.Menu || 'Unknown Assessment'}


                                                        </span>
                                                        <span className="text-blue-500 font-medium cursor-pointer"><DragIndicator /></span>
                                                    </li>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>


                    {/* Display any form errors */}
                    {formik.errors.selectedMenus && <p className="text-red-500">{formik.errors.selectedMenus}</p>}
                    {message && message}
                    {/* Save Button */}
                    <Button
                        variant='contained'
                        className='mt-4'
                        id='addChannel'
                        type='submit'
                        disabled={submitting} // Disable when submitting
                    >
                        {submitting ? 'Submitting...' : 'Save'}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default AddModal;
