import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconButton } from '@mui/material';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import useUserId from '../../services/useUserId';
import { Search } from '@mui/icons-material';

function SearchContent({ content,setContent }) {
  const UserId = useUserId();


  const getContentData = async (data) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/search-content`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...data, UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        // Handle successful response
        console.log(response);
        setContent(response.data)
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      searchType: 'all', // Dropdown value for search type
      authorName: '', // Input for author name
      companyName: '', // Input for company name
    },

    

    onSubmit: async (values, { setSubmitting }) => {
      // Prevent page reload and handle submission
      await getContentData(values);
      setSubmitting(false); // Reset submitting state after the request is done
    },
  });

  useEffect(()=>{
getContentData()
  },[])
  return (
    <div className='flex flex-row justify-between '>
      <div className='w-28' >

        <CustomSelect
          options={[
            { label: 'Assessment', value: 'assessment' },
            { label: 'Series', value: 'series' },
            { label: 'Channel', value: 'channel' },
            { label: 'All', value: 'all' },
          ]}
          dataField="value"
          dataText="label"
          label="Search Type"
          name="searchType"
          onChange={(e) => formik.setFieldValue('searchType', e.target.value)}
          value={formik.values.searchType}
        />
      </div>

      <div>
        <InputBox
          fullWidth
          inputId="authorName"
          inputName="authorName"
          inputValue={formik.values.authorName}
          onChange={formik.handleChange}
          error={formik.touched.authorName && Boolean(formik.errors.authorName)}
          helperText={formik.touched.authorName && formik.errors.authorName}
          inputLabel="Author Name"
        />
      </div>

      <div>
        <InputBox
          fullWidth
          inputId="companyName"
          inputName="companyName"
          inputValue={formik.values.companyName}
          onChange={formik.handleChange}
          error={formik.touched.companyName && Boolean(formik.errors.companyName)}
          helperText={formik.touched.companyName && formik.errors.companyName}
          inputLabel="Company Name"
        />
      </div>

      <IconButton type="submit" onClick={formik.handleSubmit} variant="contained" color="primary" >
        <Search/>
      </IconButton>
    </div>
  );
}

export default SearchContent;
