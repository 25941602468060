import React, { useEffect, useState } from 'react';
import { Button, IconButton, Slider } from '@mui/material';
import ShowJobRole from './ShowJobRole';
import ShowSkills from './ShowSkills';
import Showcompetency from './Showcompetency';
import { useLocation, useNavigate } from 'react-router-dom';
import Heading from '../reusableComp/Heading';

import { CustomSelect, CustomSelectv4, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import MUIRichTextEditor from 'mui-rte';
import {  convertToRaw } from 'draft-js';
import StanderdConstants from '../../services/StanderdConstants';
import SubscriptionPlanInfo from '../reusableComp/SubscriptionPlanInfo';
import { getAccessOptions } from '../reusableComp/IsOrganisation';
import { KeywordInput } from '../reusableComp/KeywordInput';
import { ArrowBack } from '@mui/icons-material';
import useUserId from '../../services/useUserId';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';

function EditAssessment() {

    const location = useLocation();
    const data = location.state.data;
    console.log(data)
    const [name, setName] = useState(data.nameOfAssessment);
    const [assessmentBasis, setAssessmentBasis] = useState(data.assesmentBasis);
    const [numQuestions, setNumQuestions] = useState(data.numberOfQuestions);
    const [totalMarks, setTotalMarks] = useState(data.TotalMarks);
    const [timeToAttempt, setTimeToAttempt] = useState(data.Time);
    const [assessmentType, setAssessmentType] = useState(data.assessmentType);
    const [inputSet, setInputSet] = useState();
    const [orderOfQuestions, setOrderOfQuestions] = useState(data.orderOfQuestion);
    const [difficulty, setDifficulty] = useState({ easy: 30, normal: 40, hard: 30 });
    const [loading, setLoading] = useState(false);
    const [apiResStatus, setApiResStatus] = useState('');
    const [selectedIds, setSelectedIds] = useState(); // Consolidated IDs state
    const [assessmentId, setassessmentId] = useState();
    const [assessmentSections, setAssessmentSections] = useState([{ sectionName: '', competencyId: '' }])
    const [assessmentInstructions, setassessmentInstructions] = useState();
    const [instructioncontentstate, setinstructioncontentstate] = useState('')
    const [updateinstructioncontentstate, setupdateinstructioncontentstate] = useState('')
    const [AssessmentImage, setAssessmentImage] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [AssessmentVisibilty, setAssessmentVisibilty] = useState('')
    const [accessOptions, setAccessOptions] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    const UserId=useUserId()
    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };


    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(UserId); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }

        fetchAccessOptions();
    }, []);

    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/SaveIntoDbEdit`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ AssessmentId: Id, KeyWords: selectedKeywords })
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Keyword Added")
            }
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }


    console.log(data)
    useEffect(() => {
        if (data) {
            setName(data.nameOfAssessment || '');
            setAssessmentBasis(data.assesmentBasis || '');
            setNumQuestions(data.numberOfQuestions || '');
            setTotalMarks(data.TotalMarks || '');
            setTimeToAttempt(data.Time || '');
            setAssessmentType(data.assessmentType || '');
            setOrderOfQuestions(data.orderOfQuestion || '');
            setDifficulty(data.difficultyLevel || '');
            setassessmentId(data.assessmentId || '');
            setSelectedIds(data.baseRef || []); // Update based on your logic for setting selectedIds
            setAssessmentSections(data.assessmentSections || [{ sectionName: '', competencyId: '' }]);
            setDifficulty(JSON.parse(data.difficultyLevel))
            setassessmentInstructions(data?.AssessmentInstructions || null)
            setAssessmentVisibilty(data?.Visibility || null)
            
            const keywordsArray = data?.Keywords?.split(',').map((keyword, index) => ({
                KeywordId: parseInt(data?.KeywordsId?.split(',')[index], 10), // Convert string IDs to integers
                Keyword: keyword?.trim() // Trim whitespace from the keyword
            }));
    
            // Step 2: Set the state with the formatted array
            console.log(keywordsArray)
            setSelectedKeywords(keywordsArray || []);
        }
    }, [data]);
    const navigate = useNavigate();


    useEffect(() => {
        const array = JSON.parse(data.baseRef);
        if (data.assesmentBasis == StanderdConstants.skills || data.assesmentBasis == StanderdConstants.ASSESSMENT_BASISS_JOBROLE) {
            const filteredBaseRef = array.map(ref => ({
                id: ref.id || ref.competencyId,
                name: 'Decision Making',
                weight: ref.weight !== undefined ? ref.weight : (ref.competencyWeight || null)
            }));
            setSelectedIds(filteredBaseRef);
        } else {
            const filteredBaseRef = array.map(ref => ({
                competencyId: ref.id || ref.competencyId,
                competencyWeight: ref.weight !== undefined ? ref.weight : (ref.competencyWeight || null)
            }));
            setSelectedIds(filteredBaseRef);
        }
    }, [data]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        saveKeywordIntodb(data.assessmentId)

        const payload = {
            assessmentId: data.assessmentId,
            name,
            assessmentBasis,
            baseRef: JSON.stringify(selectedIds),
            numQuestions,
            totalMarks,
            timeToAttempt,
            assessmentType,
            inputSet,
            orderOfQuestions,
            difficulty: JSON.stringify(difficulty),
            updateinstructioncontentstate,
            AssessmentImage,
            selectedPlan,
            selectedCurrency,
            subscriptionAmount,
            AssessmentVisibilty,
            UserId:StanderdConstants.UserId

        };
        const formData = new FormData();


        const isRichTextEmpty = (content) => {
            try {
                const parsedContent = JSON.parse(content); // Parse the content if it's JSON
                // Check if blocks array exists and if all blocks have empty text fields
                if (parsedContent.blocks) {
                    return parsedContent.blocks.every(block => block.text.trim() === '');
                }
            } catch (error) {
                console.error('Error parsing rich text content:', error);
            }
            return true; // Default to true if content is empty or cannot be parsed
        };

        if (isRichTextEmpty(assessmentInstructions)) {
            setApiResStatus('Assessment instructions are required.');
            setLoading(false)
            return;
        }
        // Append each property to the FormData object
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }

        console.log(payload);
        let apiEndpoint = `${process.env.REACT_APP_API_URL}/assessment/saveAssessment/update`;

        try {
            const response = await fetch(apiEndpoint, {
                method: 'post',

                body: formData
            });
            const result = await response.json();
            setassessmentId(result.assessmentId);
            setApiResStatus(result.message);
           showToast(result.message,result.status)
            if (response.ok) {
                setassessmentId(result.assessmentId);

            } else {
                console.log(result.error)
            }
        } catch (error) {
            setApiResStatus('An error occurred while creating the assessment.');
        } finally {
            setLoading(false);
        }
    };
    const handleAssessmentBasisChange = (e) => {

        setAssessmentBasis(e.target.value);

        // Clear selected IDs when assessment basis changes
        setSelectedIds([]);

        // Optionally, clear other related states if needed
        // setSelectedJobRoles([]);
        // setSelectedCompetencies([]);
        // setSelectedSkills([]);
    };

    const handleJobRolesChange = (jobRoles) => {
        setSelectedIds(jobRoles);
    };

    const handleCompetencyChange = (competencies) => {
        setSelectedIds(competencies);
    };

    const handleSkillChange = (skills) => {
        setSelectedIds(skills);
    };

    const previewQuestions = () => {
        navigate('/previewQuestion', { state: { data: assessmentId } });
    };


    const assessmentBasisData = [

        { label: 'Job Role Based', value: 'jobrole' },
        { label: 'Competency Based', value: 'competency' },
        { label: 'Skills Based', value: 'skills' },

    ]

    const assessmentTypeData = [
        { 'label': 'Same for all Participants', value: 'same' },
        { 'label': 'Create Set Of Assessment', value: 'set' },
        { 'label': 'Dynamic Question for each Participant', value: 'dynamic' }
    ]

    const assessmentOrderofQuestionData = [
        { label: 'same Order', value: 'same' },
        { label: 'shuffle', value: 'Shuffle Order' }
    ]

    // console.log(JSON.parse(assessmentInstructions))
    useEffect(() => {


        // Parse the incoming JSON string
        let contentState
        try {

            if (assessmentInstructions) {
                contentState = JSON.parse(assessmentInstructions);
                const rawContentState = {
                    ...contentState,
                    entityMap: contentState?.entityMap || {}
                };
                console.log(JSON.stringify(rawContentState))

                setinstructioncontentstate(JSON.stringify(rawContentState));
            } else {
                setinstructioncontentstate(null);

            }
        } catch (error) {
            console.error("Failed to parse assessmentInstructions:", error);
            setinstructioncontentstate(null);

        }



    }, [assessmentInstructions])
    const handleChangeRichTextBox = (state) => {
        // console.log((convertToRaw(state.getCurrentContent())))
        const rawContent = convertToRaw(state.getCurrentContent());
        // console.log(JSON.parse(rawContent));

        setupdateinstructioncontentstate(JSON.stringify(rawContent)); // Store the content as JSON
        console.log(JSON.stringify(rawContent))
    };

    const roundToNearest = (value, multiple) => {
        return Math.round(value / multiple) * multiple;
    };

    const handleSliderChange = (level, newValue) => {
        let remaining = 100 - newValue; // Calculate remaining percentage for other two levels

        let newDifficulty = { ...difficulty };

        if (level === StanderdConstants.EASY_DIFFICULTY) {
            const ratio = (difficulty.normal + difficulty.hard) / remaining;
            newDifficulty = {
                easy: newValue,
                normal: roundToNearest((difficulty.normal / ratio), 5),
                hard: roundToNearest((difficulty.hard / ratio), 5),
            };
        } else if (level === StanderdConstants.NORAML_DIFFICULTY) {
            const ratio = (difficulty.easy + difficulty.hard) / remaining;
            newDifficulty = {
                easy: roundToNearest((difficulty.easy / ratio), 5),
                normal: newValue,
                hard: roundToNearest((difficulty.hard / ratio), 5),
            };
        } else if (level === StanderdConstants.HARD_DIFFICULTY) {
            const ratio = (difficulty.easy + difficulty.normal) / remaining;
            newDifficulty = {
                easy: roundToNearest((difficulty.easy / ratio), 5),
                normal: roundToNearest((difficulty.normal / ratio), 5),
                hard: newValue,
            };
        }

        // Ensure total is 100%
        const adjustedTotal = newDifficulty.easy + newDifficulty.normal + newDifficulty.hard;
        if (adjustedTotal !== 100) {
            const diff = 100 - adjustedTotal;
            // Adjust the hard value to balance the sum while ensuring it remains a multiple of 5
            newDifficulty.hard = roundToNearest(newDifficulty.hard + diff, 5);
        }

        setDifficulty(newDifficulty);
    };


    return (
        <div className=" p-6 bg-gray-100 min-h-screen">
        <Toast
                message={message}
                isVisible={isVisible}
                type={type}
                pauseHideTimer={pauseHideTimer}
                resumeHideTimer={resumeHideTimer}
            />

            <div className="lg:w-3/4 mx-auto">
            <div className="col-span-full mb-4">
                    <IconButton
                        onClick={() => navigate('/ManageAssessments')}
                    >
                    <ArrowBack/>
                    </IconButton>
                </div>
                <div className='w-full bg-sky-600 py-1 rounded-t-md text-center text-gray-50  '><Heading heading={"Edit Assessment"} /></div>

                <form onSubmit={handleSubmit} className='flex flex-col p-6 gap-4  bg-white  rounded-lg shadow-md'>

                    <div style={{ borderBottomWidth: '1px' }} className='mb-4 p-2 border-gray-400 min-h-32  text-wrap'>

                        {instructioncontentstate ?
                            (<MUIRichTextEditor
                                label="Assessment Instructions*"
                                defaultValue={instructioncontentstate}
                                inlineToolbar={true}
                                onChange={handleChangeRichTextBox}
                            />) :
                            (
                                <MUIRichTextEditor

                                    label="Assessment Instructions*"
                                    inlineToolbar={true}
                                    onChange={handleChangeRichTextBox}
                                />
                            )
                        }


                    </div>
                    <div className="mb-4">
                        <InputBox
                            required
                            inputLabel='Name of Assessment'
                            type="text"
                            inputValue={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name of assessment"
                            className=" w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    {!AssessmentImage && data.AssessmentImage && (
                        <div className=' flex flex-row items-center mx-auto justify-center rounded-sm h-40 w-40'>
                            <img alt='Seriesbanner' src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${data.AssessmentImage}`} />
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="AssessmentImage">Assessmeng Thumbnail </label>
                        <input
                            id="edit-AssessmentImage"
                            name="AssessmentImage"
                            type="file"
                            accept="image/*"
                            onChange={(e) => setAssessmentImage(e.target.files[0])}
                        />

                    </div>

                    <div>
                        <div className="my-4">

                            <CustomSelect
                                value={assessmentBasis}
                                onChange={(e) => handleAssessmentBasisChange(e)}
                                options={assessmentBasisData}
                                label='Assessment Basis*'
                                name='value'
                                dataText='label'
                                dataField='value'


                            />
                        </div>


                        <div className='mb-4'>
                            {assessmentBasis === 'jobrole' && (
                                <ShowJobRole onJobRolesChange={handleJobRolesChange} existingData={JSON.parse(data.baseRef)} />
                            )}

                            {assessmentBasis === 'competency' && (
                                <Showcompetency onCompetenciesChange={handleCompetencyChange} existingData={selectedIds} />
                            )}

                            {assessmentBasis === 'skills' && (
                                <ShowSkills onSkillsChange={handleSkillChange} existingData={JSON.parse(data.baseRef)} />
                            )}
                        </div>
                    </div>

                    <div className="mb-4">
                        <InputBox
                            required
                            type="number"
                            inputLabel='Number of questions'
                            inputValue={numQuestions}
                            onChange={(e) => setNumQuestions(e.target.value)}
                            placeholder="Number of questions"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="mb-4">
                        <InputBox
                            required
                            type="number"
                            inputLabel='Total Marks*'
                            inputValue={totalMarks}
                            onChange={(e) => setTotalMarks(e.target.value)}
                            placeholder="Total Marks"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>





                    <div className="mb-4">
                        <InputBox
                            required
                            type="text"
                            inputValue={timeToAttempt}
                            inputLabel='Time to attempt (in minutes)*'
                            onChange={(e) => setTimeToAttempt(e.target.value)}
                            placeholder="Time to attempt (in minutes)"
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="mb-4">


                        <CustomSelect
                            value={assessmentType}
                            onChange={(e) => setAssessmentType(e.target.value)}
                            options={assessmentTypeData}
                            dataField='value'
                            dataText='label'
                            label='Assessment Type*'

                        />
                        {assessmentType === 'set' && (
                            <div className="p-4">
                                <InputBox
                                    required
                                    type="text"
                                    inputValue={inputSet}
                                    onChange={(e) => setInputSet(e.target.value)}
                                    inputId='set'
                                    inputLabel='Number Of sets*'




                                />
                            </div>
                        )}
                    </div>

                    <div className="mb-4">


                        <CustomSelect
                            value={orderOfQuestions}
                            onChange={(e) => setOrderOfQuestions(e.target.value)}
                            options={assessmentOrderofQuestionData}
                            dataField='value'
                            dataText='label'
                            label='Order Of Question*'



                        />
                    </div>
                    <SubscriptionPlanInfo
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        selectedCurrency={selectedCurrency}
                        setSelectedCurrency={setSelectedCurrency}
                        subscriptionAmount={subscriptionAmount}
                        setSubscriptionAmount={setSubscriptionAmount}
                    />

                    <div className='flex flex-col gap-2'>
                        <label style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Assessment Difficulty</label>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Easy</label>
                            <div className='w-full '>
                                {difficulty.easy}%
                                <Slider
                                    aria-label="Difficulty"
                                    value={difficulty.easy} // Bind the slider value to difficulty.easy
                                    onChange={(e, newValue) => handleSliderChange('easy', newValue)}
                                    valueLabelDisplay="auto"

                                    step={10}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Normal</label>
                            <div className='w-full '>
                                {difficulty.normal}%
                                <Slider
                                    aria-label="Difficulty"
                                    value={difficulty.normal} // Bind the slider value to difficulty.easy
                                    onChange={(e, newValue) => handleSliderChange('normal', newValue)}
                                    valueLabelDisplay="auto"

                                    step={10}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Hard</label>
                            <div className='w-full '>
                                {difficulty.hard}%

                                <Slider
                                    aria-label="Difficulty"
                                    value={difficulty.hard} // Bind the slider value to difficulty.easy
                                    onChange={(e, newValue) => handleSliderChange('hard', newValue)}
                                    valueLabelDisplay="auto"

                                    step={10}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>


                    </div>

                    <div>
                        <CustomSelectv4
                            options={accessOptions}
                            dataField="value"
                            dataText="label"
                            inputId="Visibility"
                            label="Channel Visibility*"
                            name="Visibility"
                            onChange={(selectedOption) => setAssessmentVisibilty(selectedOption)}
                            value={AssessmentVisibilty}
                        />
                    </div>

                    <div className='mb-4'>

                        <KeywordInput
                            multiple={true}
                            dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                            inputValue={selectedKeywords} // Pass the state as value
                            inputName="keywords"
                            onChange={handleKeywordsChange} // Pass the handler function
                            inputLabel="Keywords*"
                            inputId="keywords-input"
                        />

                    </div>




                    {apiResStatus && (
                        <div className='flex  justify-center align-middle gap-2'>
                            <p className="text-red-700 mb-4 ">{apiResStatus}</p>
                            {assessmentId && <div> <button className='border-none shadow-none underline text-sky-500' onClick={previewQuestions}>Preview Assessment</button></div>

                            }                        </div>
                    )}

                    <div className="flex justify-between space-x-4">
                        <Button
                            type="reset"
                            variant='outlined'
                        >
                            Reset
                        </Button>
                        <Button
                            type="submit"
                            disabled={loading}
                            variant='contained'
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default EditAssessment;
