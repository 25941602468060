import React from "react";

const OptionsList = ({ options, isMultipleCorrect }) => {
  return (
    <div className="options-list mt-2">
      {options && (
        <ul className="list-disc pl-5">
          {options.map((option) => (
            <div key={option.questionOption_id} className="mt-1">
              {isMultipleCorrect ? (
                <label>
                  <input
                    type="checkbox"
                    value={option.isCorrect}
                    defaultChecked={option.isCorrect} // Preview mode, initial check state
                    disabled // Make it read-only
                    className="mr-2"
                  />
                  {option.optionText}
                </label>
              ) : (
                <label>
                  <input
                    type="radio"
                    value={option.isCorrect}
                    checked={option.isCorrect} // Preview mode, initial check state
                    disabled // Make it read-only
                    className="mr-2"
                  />
                  {option.optionText}
                </label>
              )}
              
                {option.optionImage ? (
                    <div className=' w-40 '>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${option.optionImage}`}
                    alt="Option"
                    className="block rounded-md"
                  />
                  </div>
                ) : (
                  <></>
                )}
              
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OptionsList;
