import React, { useEffect, useState } from 'react'
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { ChartIConButton, DetailedReport, ViewIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { useLocation, useNavigate } from 'react-router-dom';
import TableComp from '../reusableComp/TableComp';
import StanderdConstants from '../../services/StanderdConstants';
import { UserCompanyId } from '../reusableComp/IsOrganisation';
import useUserId from '../../services/useUserId';

function AssessmentResultPageForAuthor() {
    const [data, setData] = useState();
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [query, setQuery] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [CompanyId,setCompanyId]=useState('')
    const navigate = useNavigate()
const UserId=useUserId()
     const location = useLocation();
     
     UserCompanyId(UserId).then(result=>{
      setCompanyId(result)
     })
     console.log(CompanyId)
      
console.log(location)
        const assessmentId= location.state.data
        console.log(assessmentId)

        function transformDateTimes(dataArray) {
            function formatUTCDate(utcDateStr) {
                const date = new Date(utcDateStr);
        
                const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true
                };
        
                return date.toLocaleString('en-US', options);
            }
        
            return dataArray.map(item => ({
                ...item,
                AssessmentDateTime: formatUTCDate(item.AssessmentDateTime)
            }));
        }
    const AllResultsOfAssessments = async () => {
       
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults/AllResultsOfAssessments`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ UserId,assessmentId })
        })
        const result = await response.json();
        if (response.ok) {
            if (result?.data?.length > 0) {
                const transformedData = transformDateTimes(result.data);

                setData(transformedData)
                
            }

        }

    }


    useEffect(()=>{
        if(data)
        {
            setfilteredData(data)
        }
    },[data])
    useEffect(() => {
        AllResultsOfAssessments()
    }, [])

    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            AllResultsOfAssessments()
        }
    }, [insertionStatus]);
    
    const handleDelete = async (id) => {

        const res = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults/delete/`, {
            method: 'delete',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({ attemptId: id })
        });
        if (res.ok) {
            console.log("deleted successfully");
            setInsertionStatus(true)
        } else {
            console.log("error occurred");
        }
    }

    const handleSearch = (e) => {
        console.log(query);
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        
            if (!searchQuery) {
                setfilteredData(data);
            } else {
                const result = data.filter(item =>
                    Object.values(item).some(value =>
                        value?.toString().toLowerCase().includes(searchQuery)
                    )
                );
                setfilteredData(result);

            
        } 
    }

    const columns = [
        { header:' Candidate Name', field: 'Name' },
        { header:'Name Of Assessment', field: 'nameOfAssessment' },
        { header:'Assessment Date Time', field: 'AssessmentDateTime' },
        { header:'Final Score', field: 'finalScore' },
    ];

    const actions = [
       
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            requiredPermission: true,
            ButtonComponent: DeleteConfirmation,
        },
       
        {
            label: 'Detailed Result',
            handler:(item) =>navigate('/Report', { state: { data: item.AssessmentAttemptId } }),
            tooltip: 'Detailed Report',
            requiredPermission: true,
            ButtonComponent: DetailedReport,

            
        }
    ]
    console.log(data)
  return (
    <div className='p-1'>
<div className='my-4 mt-6'><Heading heading='Assessment Results' /></div>

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputIdd='search'
                        inputLabel="search"
                        inputValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>

            </div>
      {/* list of assessment , which candidates has given. */}
     
      <TableComp
                data={filteredData}
                actions={actions}
                columns={columns}
                CompanyId={CompanyId}

                />

    </div>
  )
}

export default AssessmentResultPageForAuthor
