import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

function ProtectedRoute({ element: Element }) {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    // Function to fetch and set userId from the token
    const updateUserId = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const tokenData = jwtDecode(token);
                localStorage.setItem('userId', tokenData.userId);
            } catch (error) {
                console.error('Invalid token', error);
                localStorage.setItem('userId', null);
            }
        } else {
            localStorage.setItem('userId', null);
        }
    };

    useEffect(() => {
        // Check for latest token on mount
        updateUserId();

        const verifyToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/login/protected`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
        };

        verifyToken();

        // Listen for token changes across tabs
        const handleStorageChange = () => {
            updateUserId();
            verifyToken();  // Re-verify the token if it changes
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    if (isAuthenticated === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (isAuthenticated) {
        return <Element />;
    } else {
        navigate('/login', { replace: true });
        return null;
    }
}

export default ProtectedRoute;
