import React from 'react'
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';


function Main() {
    return (
        <div>

            <Sidebar>
                <Menu

                >
                    <MenuItem component={<Link to="/documentation" />}> Documentation</MenuItem>
                    <MenuItem component={<Link to="/calendar" />}> Calendar</MenuItem>
                    <MenuItem component={<Link to="/e-commerce" />}> E-commerce</MenuItem>
                </Menu>
            </Sidebar>

        </div>
    )
}

export default Main