import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconButton, MenuItem, Menu } from "@mui/material";
import {
  AccountCircleRounded,
  Logout,
  Settings,
  WhatsApp,
  MenuOutlined,
} from "@mui/icons-material";
import logoimage from "../../Logo.png";
import { SearchContext } from "../../useContext/SearchContext";

function Header() {
  const [isHomePage, setIsHomePage] = useState(false);
  const [isAuthPage, setIsAuthPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For opening/closing the dropdown menu

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const [SearchText, setSearchText] = useState("");
  const [Profile, setProfile] = useState({ Name: "", Logo: "" });
  const [profileLoaded, setprofileLoaded] = useState(true);

  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    handleMenuClose();
    navigate("/");
  };

  useEffect(() => {
    // Initialize Profile state with current localStorage values
    setProfile({
      Name: localStorage.getItem("ProfileName") || "",
      Logo: localStorage.getItem("ProfileLogo") || "",
    });

    const handleStorageChange = () => {
      setProfile({
        Name: localStorage.getItem("ProfileName"),
        Logo: localStorage.getItem("ProfileLogo"),
      });
    };

    // Listen for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
    setIsAuthPage(
      ["/login", "/signUp", "SignUp", "/SignupforCompany"].some((path) =>
        location.pathname.includes(path)
      )
    );
  }, [location.pathname]);

  const handleSidebarToggle = () => {
    // setshowSidebar(!showSidebar);
    setShowMenuIcon(false);
  };
  console.log(showMenuIcon);

  // useEffect(() => {
  //   const handleResize = () => {
  //       setShowMenuIcon(window.innerWidth < 760);
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  console.log(Profile);
  return (
    <div className="z-7">
      {/* Contact Support and Sign In/Register section */}
      <div
        className="flex flex-row justify-between items-center py-[5px] px-4 text-gray-50"
        style={{
          backgroundImage: "linear-gradient(90deg, #c86dd7, #3023ae)",
        }}
      >
        <div>
          Contact for support <WhatsApp /> 8217643532
        </div>
        <div>
          {!localStorage.getItem("userId") ? (
            <div className="flex items-center gap-2 my-1">
              <span className="rounded-full px-3 py-[6px] bg-sky-600 hover:bg-sky-600 hover:text-gray-50 text-sm">
                <Link to="/SignupforCompany">Register as Organisation</Link>
              </span>
              <span className="rounded-full px-3 py-[6px] bg-sky-600 hover:bg-sky-600 hover:text-gray-50 text-sm">
                <Link to="/login">Sign In</Link>
              </span>
            </div>
          ) : (
            <div className="relative">
              <IconButton
                onClick={handleMenuOpen}
                className="flex items-center   gap-2 focus:outline-none border-none"
                color="primary"
              >
                <span className="h-10 w-10 flex items-center justify-center  rounded-full overflow-hidden">
                  {profileLoaded ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${localStorage.getItem("ProfileLogo")}`}
                      alt="logo"
                      className="h-full w-full object-cover"
                      onLoad={() => setprofileLoaded(true)}
                      onError={() => setprofileLoaded(false)}
                    />
                  ) : (
                    <AccountCircleRounded
                      fontSize="large"
                      className="text-gray-50"
                    />
                  )}
                </span>
              </IconButton>
              {/* Dropdown menu for profile and logout */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
              className="w-full px-4 py-2 font-medium text-gray-800 hover:bg-gray-100 flex items-center text-center gap-2"
                >
             <h1 className="font-bold"> Hello,  {localStorage.getItem('ProfileName')}</h1>  
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/profile");
                  }}
                  className="w-full px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center gap-2"
                >
                  <Settings fontSize="small" />
                  <span>Profile </span>
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center gap-2"
                >
                  <Logout fontSize="small" />
                  <span>Logout</span>
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>

      {/* Main header with logo, links, and search */}
      <div className="w-full shadow-lg bg-gray-50 flex items-center justify-between px-4 -py-1">
        {/* Logo Section */}
        <div className="flex items-center">
          {showMenuIcon && (
            <IconButton
              onClick={handleSidebarToggle}
              className="w-10 text-gray-700"
            >
              <MenuOutlined />
            </IconButton>
          )}
          <Link to="/">
            <img src={logoimage} alt="Logo" className="h-14 w-40" />
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="px-1 hidden lg:block">
          <Link
            to="/channels"
            className="font-bold uppercase hover:text-sky-600"
          >
            Channels
          </Link>
        </div>
        <div className="px-1 hidden lg:block">
          <Link to="/series" className="font-bold uppercase hover:text-sky-600">
            Assessment Series
          </Link>
        </div>
        <div className="px-1 hidden lg:block">
          <Link
            to="/assessments"
            className="font-bold uppercase hover:text-sky-600"
          >
            Assessments
          </Link>
        </div>

        {/* Search InputBox */}
        <div className="lg:w-2/6 flex">
          <input
            type="text"
            id="search"
            placeholder="Search..."
            className="rounded-l-full px-4 py-2 w-full border border-gray-300 focus:outline-none"
            onChange={(e) => setSearchText(e.target.value)}
            value={SearchText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchQuery({ query: SearchText });
              }
            }}
          />
          <button
            onClick={() => setSearchQuery({ query: SearchText })}
            className="bg-sky-600 hover:bg-gray-50 hover:text-sky-600 border-sky-600 px-4 text-gray-50 rounded-r-full"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
