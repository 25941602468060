// AddressInformation Component
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, TextField } from '@mui/material';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';

function AddressInformation({ companyId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [Message, setMessage] = useState('');
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();


    // Fetch existing address information
    useEffect(() => {
        const fetchAddressInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/address-info/${companyId}`);
                if (!response.ok) throw new Error('Error fetching address information.');
                const data = await response.json();
                formik.setValues({
                    streetAddress: data.companyStreetAddress || '',
                    city: data.companyCity || '',
                    stateOrProvince: data.companyState || '',
                    postalCode: data.companyPostalCode || '',
                    country: data.companyCountry || '',
                    location: '',
                });
            } catch (err) {
                setError(err.Message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAddressInfo();
    }, [companyId]);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            streetAddress: '',
            city: '',
            stateOrProvince: '',
            postalCode: '',
            country: '',
            location: '',
        },
        validationSchema: Yup.object({
            streetAddress: Yup.string().required('Street address is required'),
            city: Yup.string().required('City is required'),
            stateOrProvince: Yup.string().required('State/Province is required'),
            postalCode: Yup.string()
                .matches(/^\d+$/, 'Postal code should contain only digits')
                .required('Postal code is required'),
            country: Yup.string().required('Country is required'),
            location: Yup.string().required('Location is required'), // Add location validation
        }),
        onSubmit: async (values) => {
            await saveInfo(values);
        },
    });

    // Fetch address suggestions
    const getAddressSuggestions = async (query) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setSuggestions(data); // Set the suggestions state
        } catch (err) {
            console.error("Error fetching location data:", err);
        }
    };

    // Handle input change for location search
    const handleLocationChange = (event, value) => {
        if (value && value.length > 2) {
            getAddressSuggestions(value); // Fetch suggestions if query length is sufficient
        } else {
            setSuggestions([]); // Clear suggestions if input is less than 3 characters
        }
    };

    // Handle selecting a suggestion
    const handleSelectSuggestion = (event, selectedValue) => {
        if (selectedValue) {
            const selectedSuggestion = suggestions.find(suggestion => suggestion.place_id === selectedValue.place_id);
    
            if (selectedSuggestion) {
                formik.setValues({
                    ...formik.values,
                    streetAddress: selectedSuggestion.display_name || '',
                    city: selectedSuggestion.address.city || '',
                    stateOrProvince: selectedSuggestion.address.state || selectedSuggestion.address['ISO3166-2-lvl4'] || '',
                    postalCode: selectedSuggestion.address.postcode || '',
                    country: selectedSuggestion.address.country || '',
                    location: selectedSuggestion.place_id, // Storing place_id for reference
                });
                setSuggestions([]); // Clear suggestions after selection
            }
        }
    };

    // Function to save updated address information
    const saveInfo = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/address-info/${companyId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (!response.ok) throw new Error('Error saving address information.');
            const result = await response.json();
            showToast(result.message,result.status)

            setMessage(result.Message);
            console.log('Address information saved successfully:', result);
        } catch (error) {
            setError(error.Message);
            console.error(error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Display loading state
    }

    if (error) {
        return <div>Error: {error}</div>; // Display error Message
    }

    return (
        <div className='max-w-[45rem] mx-auto'>
             <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
        <div className="w-full">
            <h2 className="text-2xl font-bold w-full bg-sky-600 text-gray-50 p-4 rounded-t-md text-center">
                <Heading heading='Address Information' />
            </h2>
            <form onSubmit={formik.handleSubmit} className="space-y-4 p-6 w-full mx-auto border bg-white shadow-md rounded-b-lg">
                <Autocomplete
                    options={suggestions}
                    getOptionLabel={(option) => option.display_name || ''}
                    onInputChange={handleLocationChange}
                    onChange={handleSelectSuggestion}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Location"
                            variant="standard"
                        />
                    )}
                />
                <InputBox
                    inputId="streetAddress"
                    inputLabel="Street Address*"
                    inputName="streetAddress"
                    inputValue={formik.values.streetAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
                    helperText={formik.touched.streetAddress && formik.errors.streetAddress}
                />
                <InputBox
                    inputId="city"
                    inputLabel="City*"
                    inputName="city"
                    inputValue={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                />
                <InputBox
                    inputId="stateOrProvince"
                    inputLabel="State/Province*"
                    inputName="stateOrProvince"
                    inputValue={formik.values.stateOrProvince}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.stateOrProvince && Boolean(formik.errors.stateOrProvince)}
                    helperText={formik.touched.stateOrProvince && formik.errors.stateOrProvince}
                />
                <InputBox
                    inputId="postalCode"
                    inputLabel="Postal Code*"
                    inputName="postalCode"
                    inputValue={formik.values.postalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                />
                <InputBox
                    inputId="country"
                    inputLabel="Country*"
                    inputName="country"
                    inputValue={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                />
                {Message && <div className="text-green-600">{Message}</div>}
                <button
                    type="submit"
                    className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-sky-600 border-none"
                >
                    Save
                </button>
            </form>
        </div>
        </div>
    );
}

export default AddressInformation;
