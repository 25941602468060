import { Assessment } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { useLocation } from 'react-router-dom';
import StanderdConstants from '../../services/StanderdConstants';

ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);

const SectionWisePerformance = ({AssessmentAttemptId}) => {

 const [chartData, setChartData] = useState([])
  const [Base, setBase] = useState([])
  const [CompData, setCompData] = useState([])
  const [skillData, setSkillsData] = useState([])
  const [JobroleName, setJobroleName] = useState('')

 

  const getChartData = async () => {
      let result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults/chartsData/?id=${AssessmentAttemptId}`, {
          method: 'GET',
          headers: { "Content-Type": "application/json" },
      });
      result = await result.json();
      
      const formatDataToSingleDecimal = (data) => {
        return data.map(item => {
            // Assuming the object structure has numerical values you want to round
            return {
                ...item,
                TotalMarks: parseFloat(item.TotalMarks.toFixed(1)),  // Adjust 'value' key as needed,
                TotalScore:parseFloat(item.TotalScore.toFixed(1))

            };
        });
    };
    setChartData(formatDataToSingleDecimal(result.data))
      setBase(result.base)
      setJobroleName(result.jobroleName)
  }
  useEffect(() => {
      getChartData()
  }, [])

console.log(chartData)
  // Common doughnut chart options
  const doughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '70%',
  };

  return (
    <div className="relative flex flex-col border rounded-lg p-6 shadow-lg w-full bg-gray-50">
      {/* Badge with Profile Icon */}
      <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
        <Assessment className="text-white" fontSize="medium" />
      </div>
      <h2 className="text-xl font-semibold mb-4">{Base} Wise Performance Analysis</h2>
      <div className="flex flex-col space-y-6">
        {chartData.map((item, index) => {
          // Doughnut chart data for each item
          const doughnutData = {
            labels: [item.Name, 'Remaining'],
            datasets: [
              {
                data: [
                item.TotalScore,  // Keep as number, formatted for display
                item.TotalMarks-item.TotalScore  // Same as above
                ],
                backgroundColor: ['#4caf50', '#e0e0e0'],
                borderWidth: 0,
              },
            ],
          };
          
          // Horizontal bar data for each item's percentile
          const barData = {
            labels: [''],
            datasets: [
              {
                label: '',
                data: [(item.TotalScore /item.TotalMarks || 0)*100],
                backgroundColor: '#4caf50',
              },
            ],
          };

          const barOptions = {
            responsive: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                min: 0,
                max: 100,
              },
            },
          };

          return (
            <div key={index} className="flex flex-row justify-between items-center">
              {/* Left Column - Score Stats and Doughnut Chart */}
              <div className="w-1/2 flex flex-col items-center">
                <h3 className="text-lg font-semibold mb-2">{item.Name} - Score: {item.TotalScore || 0}/{item.TotalMarks ||0}</h3>
                <span className='w-20 h-20'>
                <Doughnut data={doughnutData} options={doughnutOptions}  />
                </span>
              </div>

              {/* Right Column - Percentile Bar Graph */}
              <div className="w-1/2 px-4">
                <h3 className="text-lg font-semibold mb-2 opacity-0">percentage</h3>
                <span className='w-44 h-28 block'>
                <Bar data={barData} options={barOptions} />

                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionWisePerformance;
