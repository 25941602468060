import React, { useEffect, useState } from 'react'
import ExploreChannels from '../ExploreChannels/ExploreChannels'
import { ChevronRightOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import FeatureCards from '../reusableComp/FeatureCards';

function Home() {
    const pathname = window.location.pathname;
    const [ChannelMasterData, setChannelMasterData] = useState([])

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/getPopularPublicChannels`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const response = await result.json();
            setChannelMasterData(response.data);


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getdata();
    }, []);

    console.log('Pathname:', pathname);
    return (
        <div className='grid grid-cols-1 md:grid-cols-6 gap-0 lg:gap-4 p-0 md:p-0'>
        {/* Explore Channels Section */}
        <div className='col-span-5'>
          <ExploreChannels />
        </div>
      
        {/* Popular Channels Section */}
        <div className='col-span-1 hidden md:flex lg:flex md:flex-col lg:flex-col w-auto lg:w-full items-end gap-1 my-4'>
          <h1 className='my-6 mx-auto text-center px-1 lg:px-4 text-md'>Popular Channels</h1>
      
          {ChannelMasterData.map((item, index) => (
            <div key={index} className='w-full'>
              <Link to='/ChannelPreview' state={{ ChannelId: item.ChannelId }}>
                <div className='px-2 md:px-3 lg:px-6 w-full md:w-auto rounded-md py-2 text-lg hover:cursor-pointer hover:bg-gray-100 border flex justify-between items-center gap-2'>
                  <span className='overflow-hidden truncate'>{item.ChannelName}</span>
                  <ChevronRightOutlined />
                </div>
              </Link>
            </div>
          ))}
        </div>
      
        <div className="col-span-6">
    {/* Full-width content */}
    {/* <FeatureCards/> */}
  </div>
      </div>
  );
};
    

export default Home
