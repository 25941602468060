import React from 'react';
import * as Icons from '@mui/icons-material';

const StatsCard = ({ title, amount, color, Icon ,IconColor}) => {
  // Get the icon component dynamically from Icons
  const IconComponent = Icons[Icon];

  return (
    <div className={`bg-white shadow-lg rounded-lg p-6 flex items-center justify-between border-l-4 border-${color}`}>
      <div>     
           <p className={`text-xl font-bold text-${color}`}>{amount}</p>

        <h2 className="text-md  text-gray-700">{title}</h2>
      </div>
      <div className="flex-shrink-0">
        {/* Render the dynamically chosen icon component */}
        <span
  className={`bg-${color} p-3 shadow-lg flex items-center justify-center`}
  style={{ width: '56px', height: '56px', borderRadius: '50%' }}
>
  {IconComponent && (
    <IconComponent fontSize="large" className={`text-white`} sx={{ fontSize: 40 }} />
  )}
</span>

        
      </div>
    </div>
  );
};

export default StatsCard;
