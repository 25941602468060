import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountCircleRounded, ArrowBackOutlined, MoreVert, Share, ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import Subscription from '../reusableComp/Subscription';
import './App.css'
import { CustomButton } from '../../ButtonsIconsInputs/IconButtons';
import altimage from './Principles-of-assessment-scaled-1-1.webp'

function AssessmentPreviewPage() {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [liked, setLiked] = useState(false)
    const [totalLikes, setTotalLikes] = useState()
    const navigate = useNavigate()
const location = useLocation()
 const item = location.state.item
 useEffect(()=>{
    setLiked(item.IsLIked)
setTotalLikes(item.TotalLikes)

 },[item])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    
    const OnLike = async (Id) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/LikesCount/`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ContentId: Id, ContentType: 'channel' })
            });
            if (result.ok) {
                setTotalLikes(totalLikes + 1)
                setLiked(true)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const TakeAssessment = (assessmentId) => {
        navigate(`/assessment/`, { state: { data: assessmentId } })
    }

    return (
        <div>

<IconButton
        onClick={() => {navigate(-1)}}
    >
<ArrowBackOutlined/>
    </IconButton>
        <div className="relative flex flex-col w-full max-w-screen-lg mx-auto  bg-white shadow-lg rounded-lg">
            {/* Back Button */}
           
            {/* Image Section */}
            <div className="w-full h-56 bg-gray-200 flex items-center justify-center">
        {imgLoaded ? (
          <img
            className="w-full h-full object-cover"
            alt="thumbnail"
            src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item?.AssessmentImage}`}
            onLoad={() => setImgLoaded(true)} // Set loaded state to true when image loads
            onError={() => setImgLoaded(false)} // Set loaded state to false if there's an error
          />
        ) : (
          <img
          className="w-full h-full object-cover"
          alt="thumbnail"
          src={altimage}
        
        />
        )}
      </div>
      <div className=' flex flex-col p-4'>

            {/* Content Section */}
            <div className="mb-4">
                <Link to='/ChannelPreview' state={{ ChannelId: item.ChannelId }}>
                    <h1 className="font-bold text-3xl mb-2 text-gray-800 hover:cursor-pointer">
                        {item.ChannelName}
                    </h1>
                </Link>
                <p className='flex items-center text-lg mb-2 text-gray-600'><AccountCircleRounded className="mr-2" /> {item.CreaterName}</p>
                <p className="text-gray-700 mb-4">{item.ChannelDescription}</p>
            </div>

            {/* Actions Section */}
            <div className="flex flex-wrap justify-between items-center mb-4">
                <div className='flex gap-4 flex-wrap'>
                    {/* {!item.IsSubscribed ? (
                        <Subscription
                            Type="channel"
                            Id={item?.ChannelId}
                            SubscriptionAmount={item?.SubscriptionPlanAmount || 0}
                            SubscriptionPlanTypeId={item?.SubscriptionPlanTypeId}
                        />
                    ) : (
                        <span className="bg-green-500 text-white px-4 py-2 rounded-md">Subscribed</span>
                    )} */}

                    <button onClick={() => OnLike(item.ChannelId)} className='text-sm lss-buttons bg-gray-100'>
                        <span>{liked ? <ThumbUp fontSize='small' /> : <ThumbUpOutlined fontSize='small' />}</span>
                        <span className='ml-2'>{totalLikes}</span>
                    </button>

                    <button className='text-sm lss-buttons bg-gray-100'>
                        <span><Share fontSize='small' /></span>
                        <span className='ml-2'>Share</span>
                    </button>
                </div>
                <div>
                    <CustomButton
                        Text='Start Assessment'
                        onClick={() => TakeAssessment(item.assessmentId)}
                        className="flex-shrink-0"
                    />
                </div>
            </div>

            {/* Additional Info Section */}
            <div className="border-t pt-4">
                <h3 className="font-semibold text-lg mb-2">Additional Information</h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div className="flex flex-col">
                        <span className="font-medium">Time</span>
                        <span>{item.Time}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-medium">Total Marks</span>
                        <span>{item.TotalMarks}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-medium">Questions</span>
                        <span>{item.numberOfQuestions}</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    );
}

export default AssessmentPreviewPage;
