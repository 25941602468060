import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import './App.css';
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, InputBox, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';

function EditModal({ handleClose, setStatus, Data }) {
    const [CandidateList, setCandidateList] = useState([]); // Fetching candidates related to the batch
    const [Message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    const formik = useFormik({
        initialValues: {
            GroupId: '',
            GroupName: '',
            candidateIdArray: [], // Holds the selected candidate IDs
        },
        onSubmit: values => {
            console.log(values);
            sendDataToServer(values);
        }
    });

    const getCandidates = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.USERS_TABLE_NAME })
            });
            const response = await result.json();
            showToast(response.message,response.status)
            if (result.ok) {
                setCandidateList(response.data);
            } else {
                setMessage(response.Message);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getCandidates();
    }, []);

    const sendDataToServer = async (data) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/candidateGroup/Edit`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
            const response = await result.json();
            if (result.ok) {
                setStatus(true);
                setMessage(response.Message);
            } else {
                setMessage(response.Message);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        if (Data) {
            const idarray = Data.Candidates.map(item => item.CandidateId);
            formik.setValues({
                GroupId: Data.GroupId || '',
                GroupName: Data.GroupName || '',
                candidateIdArray: idarray || [],
            });
        }
    }, [Data]);

    const setValues = (e, v) => {
        console.log(e)
        console.log(v)

    }

    return (
        <div className='modal-overlayy flex justify-center items-center fixed inset-0 z-50 bg-black bg-opacity-50 overflow-auto'>
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className='modal-contentt bg-white rounded-lg shadow-lg w-full max-w-4xl overflow-auto h-auto max-h-[90vh]'>
                <div className='flex justify-between items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4 rounded-t-lg'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Candidate Group"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                {/* Modal body */}
                <form onSubmit={formik.handleSubmit} className='flex flex-col p-6 gap-4'>
                    <div className='w-full'>
                        <InputBox
                            inputId='GroupName'
                            inputLabel='Group Name'
                            inputName='GroupName'
                            inputValue={formik.values.GroupName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>

                    <div className='my-2'>
                        <InputWithDropDownv2
                            data={CandidateList}
                            dataField="Id"
                            dataText="Name"
                            inputId="selectCandidate"
                            inputLabel="Select Candidate"
                            multiple={true}
                            onChange={(event, newValue) => {
                                // Update formik with array of selected IDs
                                formik.setFieldValue('candidateIdArray', newValue.map(item => item.Id));
                            }}
                            inputValue={CandidateList?.filter(item => (formik.values.candidateIdArray || []).includes(item.Id)) || []}  // Filter selected objects based on formik.values.usersIdArray
                        />
                    </div>

                    {Message && <p className="text-red-500">{Message}</p>}

                    <Button type="submit" disabled={loading} variant='contained'>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditModal;
