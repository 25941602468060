import React from 'react';
import { ResponsivePie } from '@nivo/pie';



const DonutChart = ({ data, centerLabel }) => (
    <div style={{ position: 'relative', height: 150, width: 150 }}>
        <ResponsivePie
            data={data}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.7}
            padAngle={1}
            cornerRadius={2}
            colors={{ datum: 'data.color' }}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            isInteractive={false}
        />
        <div style={{
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)', textAlign: 'center',
            fontSize: '16px', fontWeight: 'bold', color: '#333'
        }}>
            {centerLabel}
        </div>
    </div>
);

const DonutChartsRow = ({ Chartdata, Base, Name }) => {
    return (
        <div className='rounded-md' style={{ display: 'flex', justifyContent: 'space-around', padding: '20px', backgroundColor: '#F8F8F8' }}>
            <span> {Base}  <>- {Name && Name}</></span>
            {Chartdata.map((comp, index) => {
                const data = [
                    { id: 'score', value: comp.score, color: '#4caf50' },
                    { id: 'total', value: comp.total - comp.score, color: '#f0f0f0' }
                ];

                return (
                    <div key={index} style={{ textAlign: 'center' }}>
                        <DonutChart data={data} centerLabel={`${comp.score}/${comp.total}`} />
                        <div style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}>{comp.nameOfAssessment}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default DonutChartsRow;
