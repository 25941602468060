

import React, { useEffect, useState } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { AddIconButton, EditIconButton, } from '../../../ButtonsIconsInputs/IconButtons';
import AddModal from './AddModal';
import TableComp from '../../reusableComp/TableComp';
import DeleteConfirmation from '../../reusableComp/Deletecomfirmation';
import EditModal from './EditModal';
import StanderdConstants from '../../../services/StanderdConstants';


function Organisations() {
    const [OrgData, setOrgData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentDatarow, setcurrentDatarow] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()


    useEffect(() => {
        if (insertionStatus) {
            getData()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, OrgData]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (Data, index) => {
        setcurrentDatarow(Data); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentDatarow(null); console.log("called") }

    const getData = async () => {
        let result = await fetch(`${api_url}/companies/getData`, {
            method: 'Post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ Id: 2 })
        });
        result = await result.json();
        console.log(result);
        setOrgData(result.data);
        setfilteredData(result.data);
        console.log(OrgData)
    }

    useEffect(() => {
        console.log('hello')
        getData();
    }, []);



    // const handleSearch = (e) => {
    //     console.log(query);
    //     console.log(e.target.value)
    //     const searchQuery = e.target.value.toLowerCase();
    //     setQuery(searchQuery);
    //     if (!searchQuery) {
    //         setfilteredData(OrgData);
    //     } else {
    //         console.log("here")
    //         const result = OrgData.filter(Role => Role.roleName.toLowerCase().includes(searchQuery));
    //         console.log(result)
    //         setfilteredData(result);
    //     }
    // }
    const handleSearch = (e) => {

        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(OrgData);
        } else {
            const result = OrgData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            setfilteredData(result);
        }
    }

    useEffect(() => {
        console.log("change")
        if (editStatus) {
            getData()
            setEditStatus(false)
        }
    }, [editStatus]);

    const handleDelete = async (item) => {
        let Id = Object.values(item)[0]
        console.log(Id)
        console.log(item)

        const res = await fetch(`${api_url}/companies/Delete/?CId=${Id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
            console.log("deleted successfully");
            getData();
        } else {
            console.log("error occurred");
        }
    }

    const columns = [
        { header: StanderdConstants.HEADER_COMPANY_NAME, field: StanderdConstants.DBTABLE_COLUMN_COMPANY_NAME },
        { header: StanderdConstants.HEADER_COMPANY_TYPE, field: StanderdConstants.DBTABLE_COLUMN_COMPANY_TYPE }
    ];


    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: (item)=>{navigate('/OrganisationProfilePage/',{state:{CompanyId:item.CompanyId}})},
            // handler: (item)=>{console.log(item)},
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            // requiredPermission: permissionsNameForThisPage.Edit
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,

            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            // requiredPermission: permissionsNameForThisPage.Edit

        }

    ];



    return (
        <div className="container mx-auto py-8">


            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchOrg'
                        inputLabel="Search Org"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>

                {<div>
                    <AddIconButton action={handleopen} id={"organisation"} tooltip='Add new role' />
                    {open && <AddModal handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}

                </div>}

            </div>

            {/* {<DataTable UserData={filteredData} handleDelete={handleDelete} handleEditOpen={handleEditOpen} />} */}
            <TableComp
                actions={actions}
                columns={columns}
                data={filteredData}
                IsSuperAdmin={true}
            />
            {editOpen && (
                <EditModal
                    existingData={currentDatarow}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                />
            )}
        </div>
    );
}

export default Organisations;
