import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericAssessmentCard from './GenericAssessmentCard';
import { IconButton } from '@mui/material';
import { ArrowBack, ArrowBackOutlined } from '@mui/icons-material';
import altimage from './Principles-of-assessment-scaled-1-1.webp'

function SeriesPreview() {
    const [AssessmentOfSeries, setAssessmentOfSeries] = useState([]);
       
    const location = useLocation();
    const navigate= useNavigate()
    const seriesId = location.state.seriesId;

    const ViewAssessmentOfSeriesData = async (SeriesId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/AssessmentOfSeries`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SeriesId })
            });
            const response = await result.json();
            setAssessmentOfSeries(response.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (seriesId) {
            ViewAssessmentOfSeriesData(seriesId);
        }
    }, [seriesId]);

    return (
<div>
        <IconButton
        onClick={() => {navigate(-1)}}
    >
<ArrowBackOutlined/>
    </IconButton>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
            {/* Back Button */}
              

            {/* Assessment Of Series */}
            {AssessmentOfSeries.map((item, index) => (
                <GenericAssessmentCard item={item} ShowSubscribeButton={true} key={index} />
            ))}
        </div>
        </div>
    );
}

export default SeriesPreview;
