import { Button, CircularProgress, IconButton, Radio, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCircleOutline, ArrowBack, AttachFile, CircleOutlined, Close, CloseOutlined, ImageOutlined, Remove, RemoveCircleOutline } from '@mui/icons-material';
import ImageUpload from '../reusableComp/ImageUpload'; // Import the ImageUpload component
import SkillsDropdown from './SkillsDropdown';
import Heading from '../reusableComp/Heading';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import StanderdConstants from '../../services/StanderdConstants';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';

function EditQuestion() {
    const api_url = process.env.REACT_APP_API_URL;
    const [skill, setSkill] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [question, setQuestion] = useState('');
    const [questionImages, setQuestionImages] = useState([]);
    const [difficulty, setDifficulty] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([{ option: '', image: null, isCorrect: false }]);
    const [freeTypeAnswer, setFreeTypeAnswer] = useState('');
    const [freeTypeImage, setFreeTypeImage] = useState(null);
    const [api_res_status, setApi_res_status] = useState(null);
    const [questionId, setQuestionId] = useState()
    const [OptionLoading,setOptionLoading]=useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state.data;
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    useEffect(() => {
        getAnswer(data.questionId, data.questionType)
        getQuestionImages(data.questionId)
        setQuestionId(data.questionId)
        setQuestion(data.question)
        setDifficulty(data.difficulty)
        setSkill(data.skillId)
        setQuestionType(data.questionType)
        console.log(data)


    }, [data])

console.log(options)
    const getAnswer = async (QuestionId, QuestionType) => {
        setOptionLoading(true)
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/getAnswers`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ questionId: QuestionId, questionType: QuestionType })
            });
            const response = await result.json()
            if (result.ok) {
                setOptionLoading(false)
                if (QuestionType.includes(StanderdConstants.MCQ)) {
                    const updatedOptions = await Promise.all(response.data.map(async (option) => {

                                return {
                            option: option.optionText,
                            image: option.optionImage,
                            isCorrect: option.isCorrect
                        };
                    }));

                    setOptions(updatedOptions)

                }

                else {
                    try {
                        const Answer = response.data;
                        setFreeTypeAnswer(Answer.answerText)
                       if(Answer.answerImage){ const imageResponse = await fetch(Answer.answerImage);

                        // Check if the fetch was successful
                        if (!imageResponse.ok) {
                            throw new Error('Failed to fetch image: ' + Answer.answerImage);
                        }

                        // Convert the response to a Blob
                        const blob = await imageResponse.blob();

                        // Create a File object from the Blob
                        const file = new File([blob], 'image-' + Answer.answerText.replace(/\s+/g, '-') + '.jpg', { type: blob.type });

                        // Return the File object
                      
                        setFreeTypeImage(file)}
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        return null; // Return null if an error occurs
                    }


                }
            }
        } catch (error) {

            console.log(error.message);
        }
    };


    const getQuestionImages = async (questionId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/questionImages/?questionId=${questionId}`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const imageUrls = await result.json();
            if (result.ok) {
                // const imageFiles = await Promise.all(
                //     imageUrls.map(async (url) => {
                //         const response = await fetch(url);
                //         const blob = await response.blob();
                //         return new File([blob], url.split('/').pop(), { type: blob.type });
                //     })
                // );
                setQuestionImages(imageUrls); // Store files in the state
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + questionImages.length > 10) {
            alert('You can only upload a maximum of 10 images.');
            return;
        }
        setQuestionImages([...questionImages, ...files]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = questionImages.filter((_, i) => i !== index);
        setQuestionImages(updatedImages);
    };

    const handleOptionImageChange = (index, image) => {
        const updatedOptions = [...options];
        updatedOptions[index].image = image;
        setOptions(updatedOptions);
    };

    const handleCorrectOptionChange = (index) => {
        const updatedOptions = [...options];
        if (questionType.includes(StanderdConstants.MCQSINGLE)) {
            updatedOptions.forEach((option, i) => {
                option.isCorrect = i === index;
            });
        } else {
            updatedOptions[index].isCorrect = !updatedOptions[index].isCorrect;
        }
        setOptions(updatedOptions);
    };

    const handleSubmit = async () => {
        console.log("clicked")
        if (!skill || !questionType || !question || !difficulty) {
            setApi_res_status('Please fill in all required fields.');
            return;
        }

        if (questionType.includes(StanderdConstants.MCQ)) {
            if (options.some((option) => !option.option)) {
                setApi_res_status('Please fill in all option fields.');
                return;
            }
            if (!options.some((option) => option.isCorrect)) {
                setApi_res_status('Please mark at least one option as correct.');
                return;
            }
        } else {
            if (!freeTypeAnswer) {
                setApi_res_status('Please fill in the free text answer.');
                return;
            }


        }

        setLoading(true);
        const formData = new FormData();
        formData.append('skill', skill);
        formData.append('questionType', questionType);
        formData.append('question', question);
        formData.append('difficulty', difficulty);
        formData.append('UserId',StanderdConstants.UserId)
        questionImages.forEach((image) => {
            formData.append('questionImages', image);
        });

        if (questionType.includes('mcq')) {
            options.forEach((option, index) => {
                formData.append(`options[${index}][option]`, option.option);
                if (option.image) {
                    console.log(option.image)
                    formData.append(`optionsimage`, option.image);
                }
                if (typeof option.image === 'string') {
                    // If image is a string, prepend 'upload/' to it
                    formData.append(`options[${index}][imagename]`, `uploads/${option?.image}`);
                } else if (option.image instanceof File) {
                    // If image is a File object, use the file name with 'upload/' prefix
                    formData.append(`options[${index}][imagename]`, `uploads/${option?.image.name}`);
                } else {
                    // If image is not present, set to null
                    formData.append(`options[${index}][imagename]`, '');
                }
                formData.append(`options[${index}][isCorrect]`, option.isCorrect);
            });
        } else {
            formData.append('freeTypeAnswer', freeTypeAnswer);
            if (freeTypeImage) {
                formData.append('freeTypeImage', freeTypeImage);
            }
        }

        try {
            console.log(formData)
            const response = await fetch(`${api_url}/questions_answers/update/?id=${questionId}`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            showToast(data.message,data.status)
            if (response.ok) {
                setApi_res_status('Question and Answer submitted successfully!');
            } else {
                setApi_res_status('Failed to save question and answer.');
            }
        } catch (error) {
            setApi_res_status('An error occurred while saving.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setSkill('');
        setQuestionType('');
        setQuestion('');
        setQuestionImages([]);
        setOptions([{ option: '', image: null, isCorrect: false }]);
        setFreeTypeAnswer('');
        setFreeTypeImage(null);
        setApi_res_status(null);
        setDifficulty('');
    };

    const TypeOfQuestion = [
        { label: 'Multiple Choice - Single Correct', value: 'mcq-single' },
        { label: 'Multiple Choice - Multiple Correct', value: 'mcq-multiple' },
        { label: 'Free Text', value: 'free' },


    ]

    const difficultyLevelData = [
        { label: 'Easy', value: 'easy' },
        { label: 'Normal', value: 'normal' },
        { label: 'Hard', value: 'hard' }
    ]
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className="lg:w-3/4 mx-auto ">
                <div className="col-span-full mb-4">
                    <IconButton
                        onClick={() => navigate('/ManageQuestions')}
                    >
                        <ArrowBack/>
                    </IconButton>
                </div>
                <div className='w-full bg-sky-600 rounded-t-md text-gray-50 py-2'>
                    <Heading heading={"Edit Question"} />
                </div>
                <div className='p-6 bg-white  rounded-lg shadow-md'>
                    {/* <div className="mb-4">
                        <SkillsDropdown skill={skill} setSkill={setSkill} />
                    </div> */}
                    <div className="flex justify-between gap-3 w-full mb-4">


                        <div className='w-1/2'>
                            <CustomSelect
                                className=' w-full'
                                options={TypeOfQuestion}
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                label='Type Of Question'
                                dataField='value'
                                dataText='label'


                            />
                        </div>

                        <div className='w-1/2'>

                            <CustomSelect
                                className='w-1/2'
                                options={difficultyLevelData}
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                                label='Difficulty Level'
                                dataField='value'
                                dataText='label'

                            />
                        </div>
                    </div>
                    <div className="relative mb-4">
                        <div className="flex items-center relative mb-2">
                            <InputBox

                                inputLabel="Question"
                                inputValue={question}
                                multiline='true'
                                type='text'
                                onChange={(e) => setQuestion(e.target.value)}
                                className="w-max   border  "
                            />

                            <label className=" cursor-pointer -mb-6 mx-2">
                                <ImageOutlined title='upload image' />
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChange}
                                    className="hidden"


                                />
                            </label>
                        </div>
                        <ImageUpload images={questionImages} onRemove={handleRemoveImage} />
                    </div>

                    {questionType.includes('mcq') &&  (!OptionLoading ? (
                        <div className="  space-y-6 mb-4">
                            {options.map((option, index) => (
                                <div key={index} className="relative">
                                    <div className="flex align-baseline relative mb-4 gap-1">
                                        <Radio
                                            type={questionType === 'mcq-single' ? 'radio' : 'checkbox'}
                                            name="correctOption"
                                            checked={option.isCorrect}
                                            onChange={() => handleCorrectOptionChange(index)}
                                            className=" self-end"
                                        />
                                        <div
                                            className="block w-full  self-end  rounded-md mb-3"

                                        >
                                            <InputBox
                                                inputLabel={`Option ${index + 1}`}
                                                inputValue={option.option}
                                                multiline='true'
                                                onChange={(e) => {
                                                    const updatedOptions = [...options];
                                                    updatedOptions[index].option = e.target.value;
                                                    setOptions(updatedOptions);
                                                }}
                                            />
                                        </div>
                                        <label className=" cursor-pointer self-end mx-2  mb-3 title='upload image'">
                                            <ImageOutlined />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleOptionImageChange(index, e.target.files[0])}
                                                className="hidden"

                                            />
                                        </label>
                                        <button className='w-5 border-none mx-2  p-0' onClick={() => setOptions(options.filter((_, i) => i !== index))} ><CloseOutlined className='-mb-3 p-0' /></button>
                                    </div>
                                    {option.image && (
                                        <div className="flex items-center mb-4 ml-2">
                                          

                                           {typeof(option.image)=='string'? (<img
                                                src={option.image}
                                                alt={`option ${index}`}
                                                className="w-32 h-32 object-cover rounded-md"
                                            />):(
                                                <img
                                                src={URL.createObjectURL(option.image)}
                                                alt={`option ${index}`}
                                                className="w-32 h-32 object-cover rounded-md"
                                            />
                                            )
                                            
                                            }
                                            <CloseIconButton
                                                action={() => {
                                                    const updatedOptions = [...options];
                                                    updatedOptions[index].image = null; // Correctly update the image in the specific option
                                                    setOptions(updatedOptions);
                                                }}
                                                tooltip="Remove image"
                                                iconColor="text-gray-700"
                                                
                                            />


                                        </div>
                                    )}

                                </div>
                            ))}
                            <div className=''>
                                <button
                                    onClick={() => setOptions([...options, { option: '', image: null, isCorrect: false }])}
                                    className='text-gray-600 ml-2 hover:text-gray-900 border-none'
                                    title='Add option'
                                    id='AddOptionButton'
                                >
                                    <CircleOutlined fontSize='medium' className='mr-2' /> Add Option
                                </button>
                            </div>

                        </div>
                    ) : (<span className='flex justify-center'><CircularProgress /></span>))}
                    
                    {questionType === 'free' && (
                        <div>
                            <div className="relative mb-4">
                                <div className="flex items-center relative">
                                    <div className='w-full mr-1'>
                                        <InputBox
                                            id='textAnswer'
                                            inputLabel="Type free text answer"
                                            inputValue={freeTypeAnswer}
                                            onChange={(e) => setFreeTypeAnswer(e.target.value)}
                                            multiline='true'
                                            className="block w-full p-2 pr-14 border border-gray-300 rounded-md"
                                        />
                                    </div>
                                    <label className=" cursor-pointer">
                                        <ImageOutlined />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => setFreeTypeImage(e.target.files[0])}
                                            className="hidden"
                                            id='textImage'
                                        />
                                    </label>
                                </div>
                            </div>
                            {freeTypeImage && (
                                <div className="flex items-center mb-4">
                                    <img src={URL.createObjectURL(freeTypeImage)} alt="free type answer" className="w-32 h-32 object-cover rounded-md" />

                                    <CloseIconButton action={() => setFreeTypeImage('')} tooltip='remove image' iconColor='text-gray-700' />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="mb-4">
                        <SkillsDropdown skill={skill} setSkill={setSkill} />
                    </div>

                    {api_res_status && (
                        <p className="text-red-700 mb-4 text-center">{api_res_status}</p>
                    )}

                    <div className="flex justify-between space-x-4">
                        <Button onClick={resetForm} variant="outlined" color="secondary">
                            Reset
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditQuestion;
