import React, { useContext, useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import GenericChannelCard from '../Channel/GenericChannelCard';
import { EditIconButton, InviteButton, LikeButton } from '../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { useNavigate, useNavigation } from 'react-router-dom';
import GenericAssessmentCard from '../Channel/GenericAssessmentCard';
import GenericSeriesCard from '../Channel/GenericSeriesCard';
import { IconButton } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import useUserId from '../../services/useUserId';
import { SearchContext } from '../../useContext/SearchContext';
import usePermissions from '../reusableComp/PermissionCheck';

function Channels() {
    const [ChannelMasterData, setChannelMasterData] = useState([])
    const [error, setError] = useState(null);
    const [ShowChannelContent, setShowChannelContent] = useState(false)
    const [filteredData, setfilteredData] = useState([]);
    const [query, setQuery] = useState('');
    const UserId=useUserId()
    const { filterActionsByPermission, hasPermission } =usePermissions();

    const { searchQuery, setSearchQuery } = useContext(SearchContext);
const navigate=useNavigate()
    const getContentData = async (data) => {
        try {
          const result = await fetch(
            `${process.env.REACT_APP_API_URL}/Invitation/search-content`,
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ searchType: "channel", searchQuery:searchQuery.query , UserId }),
            }
          );
          const response = await result.json();
          if (result.ok) {
            // Handle successful response
            console.log(response);
            setChannelMasterData(response.data?.channels);
            setfilteredData(response.data?.channels);
          }
        } catch (error) {
          // Handle error
          console.log(error);
        }
      };

      useEffect(()=>{
        if(searchQuery)
        {
            getContentData()
    
        }
          },[searchQuery]) 

          useEffect(() => {
            getContentData();
          }, []);
          
    const handleSearch = (e) => {

        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(ChannelMasterData);
        } else {
            const result = ChannelMasterData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            setfilteredData(result);
        }
    }



    const MyActions = [
        {
          label: "Invite",
          handler: (item) => {
            navigate("/InviteCandidate", { state: { content: item } });
          },
          tooltip: "Invite",
          ButtonComponent: InviteButton,
          requiredPermission:'Assessment-Invite'
        },
      
      ];
      const AllowedActions = filterActionsByPermission(MyActions)

    return (

        <div className='p-4'>
           
            {<div>
                <div className="flex flex-row justify-start p-4 py-4  lg:w-1/5 md:2/5 ">
                {    searchQuery.query &&       <p>Showing result for {searchQuery.query}</p>
            }
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {filteredData.map((item, index) => {
                        let badgeColor = "";
                        let borderColor = "";

                        // Define badge and border color based on AssignmentType
                        badgeColor = "bg-purple-500";   
                        if (item.contentType == "channel") {
                            return (
                              <GenericChannelCard
                                title={item.ChannelName}
                                subtitle={item.CreaterName}
                                imageSrc={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
                                badgeText="Channel"
                                badgeColor={badgeColor}
                                actions={AllowedActions}
                                item={item}
                                ButtonColor={badgeColor}
                              />
                            );
                          } 
                    })}


                </div>
            </div>}
        </div>
    )
}

export default Channels