import { Button, Divider, Tabs, Tab } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Heading from '../reusableComp/Heading';
import { InputBox, PasswordInputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { Email } from '@mui/icons-material';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [Loading,setLoading]=useState(false)

    const { email,Invitetoken } = useParams();

    
    const [message, setMessage] = useState({
        status: '',
        message: '',
    });

    const navigate = useNavigate();

    // Validation schema using Yup
    const validationSchema = Yup.object({
        Email: Yup.string()
            .email('Invalid email address')
            .required('Email is required*'),
        Password: Yup.string()
            .required('Password is required*'),
    });

    const formik = useFormik({
        initialValues: {
            Email: '',
            Password: '',
        },
        validationSchema, // Attach Yup validation schema
        onSubmit: values => {
            console.log(values);
            LoginAction(values);
        },
    });
useEffect(()=>{
    if(email)
        {
            formik.setFieldValue('Email',email)
        }
},[])
    
    const LoginAction = async (values) => {
       setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...values, Invitetoken }),
        });

        const result = await response.json();
        console.log(result);

        if (response.ok) {

            localStorage.setItem('token', result.token);
            localStorage.setItem('userId', result.user.id);
            localStorage.setItem('ProfileName',result.ProfileName)
            localStorage.setItem('ProfileLogo',result.ProfileLogo)
           setLoading(false)
            setMessage({
                status: 'green',
                message: result.message,
            });
            navigate('/');
            if (Invitetoken) {
                navigate(`/${result.redirectpaht}/`, { state: { data: result.ContentId } });
            } else {
                navigate('/');
            }
        } else {
            setLoading(false)
            setMessage({
                status: 'red',
                message: result.message,
            });
        }
    };

    return (
        <div className="flex justify-center  min-h-screen w-full bg-gray-100">
            <div className='flex flex-col my-5 '>
                <div className="w-full bg-sky-600 text-gray-50  rounded-t-md">
                    <h1 className="text-2xl text-center p-2">Wise Assessment</h1>
                    <Divider orientation="horizontal" />
                </div>
                <form
                    className="flex flex-col gap-6 border border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full max-w-md"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <Heading heading={"Login"} />

                

                    <InputBox
                        autoComplete="off"
                        inputId="Email"
                        inputLabel="Email"
                        inputName="Email"
                        inputValue={formik.values.Email}
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='w-full'
                        error={formik.touched.Email && Boolean(formik.errors.Email)}
                        helperText={formik.touched.Email && formik.errors.Email}
                    />

                    <PasswordInputBox
                        inputId="Password"
                        inputLabel="Password"
                        inputName="Password"
                        inputValue={formik.values.Password}
                        type={showPassword ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='w-full'
                        error={formik.touched.Password && Boolean(formik.errors.Password)}
                        helperText={formik.touched.Password && formik.errors.Password}
                    />
  <Link to='/ForgotPassword' className='text-red-900'>forgot password ?</Link>
                    <p className={`${message.status === 'red' ? 'text-red-600' : 'text-green-600'} text-center`}>
                        {message.message}
                    </p>

                    <Button
                        id='loginbutton'
                        type="submit"
                        variant="contained"
                        disabled={Loading}
                        color="primary"
                        size="large"
                    >
                        {Loading ? 'Signing in...' : 'Sign In'}
                    </Button>

                    <p className="text-center text-gray-700">
                        <span className='mx-2'>
                        Don't have an account? <Link to="/signUp" className="text-blue-900">Create</Link> a new one
                        </span>
                        <span>
                        or  <Link to="/SignupforCompany" className="text-blue-900">Register as Organisation</Link> 
                  
                        </span>
                         

                    </p>
                   
                </form>
            </div>
        </div>
    );
}

export default Login;
