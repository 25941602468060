

import React, { useEffect, useState } from 'react';
import './App.css';
import AddUserRoleModal from './AddUserRoleModal';

import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import TableComp from '../../reusableComp/TableComp';
import EditUserRoleModal from './EditUserRoleModal';
import DataTable from './DataTable';
import usePermissions  from '../../reusableComp/PermissionCheck';

function ManageUserRoles() {
    const [UserRoleData, setUserRoleData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [current, setcurrent] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const hasPermission=usePermissions();

    useEffect(() => {
        if (insertionStatus) {
            getUserRoleDetails()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, UserRoleData]);
 
    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (UserData, index) => {
        setcurrent(UserData); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrent(null); }

    const getUserRoleDetails = async () => {
        let result = await fetch(`${api_url}/UserRoles/getData`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
        console.log(result);
        setUserRoleData(result.data);
        setfilteredData(result.data);
        console.log(UserRoleData)
    }

    useEffect(() => {
        getUserRoleDetails();
    }, []);

    const handleDelete = async (UserId) => {

        const res = await fetch(`${api_url}/UserRoles/delete/?UserId=${UserId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
            console.log("deleted successfully");
            getUserRoleDetails();
        } else {
            console.log("error occurred");
        }
    }

    const handleSearch = (e) => {
        console.log(query);
        console.log(e.target.value)
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(UserRoleData);
        } else {
            console.log("here")
            const result = UserRoleData.filter(User => User.UserName.toLowerCase().includes(searchQuery));
            console.log(result)
            setfilteredData(result);
        }
    }

    useEffect(() => {
        console.log("change")
        if (editStatus) {
            getUserRoleDetails()
            setEditStatus(false)
        }
    }, [editStatus]);

    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Roles For The User"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchUserRole'
                        inputLabel="Search UserRole"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission('UserRole-Add') && <div>

                    <AddIconButton action={handleopen} id={"addUserRole"} tooltip='Add new role' />
                    {open && <AddUserRoleModal handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
                </div>}
            </div>


            {/* <TableComp
                data={filteredData}
                columns={columns}
                actions={actions}
            /> */}

            {UserRoleData.length > 0 && <DataTable UserData={filteredData} handleDelete={handleDelete} handleEditOpen={handleEditOpen} />}

            {editOpen && (
                <EditUserRoleModal
                    Data={current}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                />
            )}
        </div>
    );
}

export default ManageUserRoles;
