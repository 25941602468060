

import React, { useEffect, useState } from 'react';
import { FastField, useFormik } from 'formik';
import { Tabs, Tab, Button, Divider } from '@mui/material';
import * as Yup from 'yup';
import { CustomSelectv5, InputBox, PasswordInputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Heading from '../reusableComp/Heading';
import StanderdConstants from '../../services/StanderdConstants';

const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required('Organization Name is required'),
    organizationType: Yup.string().required('Organization Type is required'),
    organizationContactNumber: Yup.string()
        .matches(/^[0-9]*$/, 'Contact Number must be digits')
        .required('Contact Number is required'),
    website: Yup.string().url('Invalid URL format'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
        termsAndConditions: Yup.bool()
        .oneOf([true], 'You must accept the terms and conditions')
});

const SignUpForCompany = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [message, setMessage] = useState({
        status: '',
        message: '',
        Role: '',
    });
    const [Loading,setLoading]=useState(false)

    useEffect(()=>{
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        },[])
        
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            organizationName: '',
            organizationType: '',
            organizationContactNumber: '',
            website: '',
            UserName:'',
            UserEmail: '',
            organizationEmail:'',

            UserPhoneNo:'',
            password: '',
            confirmPassword: ''

        },
        // validationSchema,
        onSubmit: (values) => {
            console.log('Form submitted with values:', values);
            SignupAction(values)
            // Replace with actual submit logic, e.g., API call
            // navigate('/success'); // Redirect on success
        },
    });


    const { Email, CompanyId } = useParams()

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleNext = () => setActiveTab((prev) => Math.min(prev + 1, 2));
    const handlePrevious = () => setActiveTab((prev) => Math.max(prev - 1, 0));


    const SignupAction = async (values) => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgAuth/CreateOrganization`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...values, CompanyId,RoleId:StanderdConstants.OrganisationAdminroleId }),
        });

        const result = await response.json();
         setLoading(false)
        if (response.ok) {
            console.log('Signed Up');
            localStorage.setItem('token', result.token);
            localStorage.setItem('userId', result.user.id)
            navigate('/')

            setMessage({
                status: 'green',
                message: result.message,
            });



        } else {
            setMessage({
                status: 'red',
                message: result.message,
            });
        }
    };


    return (
        <div className="flex justify-center items-center min-h-screen  bg-gray-100">
            <div className="flex flex-col w-5/6 md:w-4/6 lg:w-3/6 my-5">
                <div className="w-full bg-sky-600 text-gray-50 h-1/6 rounded-t-md">
                    <h1 className="text-2xl text-center p-2">Register As A Organistaion </h1>
                    <Divider orientation="horizontal" />
                </div>
                <div className=' border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full'>
                <form
                    className="flex flex-col gap-6"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <Heading heading={"Let's Create Your Account"} customecss='font-bold' />

                    <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                        <Tab label="Basic Information" />
                        <Tab label="Account Setup" />
                    </Tabs>

                    {/* Basic Information Tab */}
                    {activeTab === 0 && (
                        <div className="mt-4">
                            <div className='my-2'>
                                <InputBox
                                    
                                    inputId="organizationName"
                                    inputLabel="Organization Name*"
                                    inputName="organizationName"
                                    inputValue={formik.values.organizationName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                                    helperText={formik.touched.organizationName && formik.errors.organizationName}
                                />
                            </div>

                            <div className='my-2'>
                                <CustomSelectv5
                                    name="organizationType"
                                    label="Type of Organization*"
                                    dataText="name"
                                    dataField="id"
                                    options={[
                                        { id: 'Non-profit', name: 'Non-profit' },
                                        { id: 'Business', name: 'Business' },
                                        { id: 'Educational Institution', name: 'Educational Institution' },
                                        { id: 'Government', name: 'Government' },
                                        { id: 'Other', name: 'Other' },
                                    ]}
                                    value={formik.values.organizationType}
                                    onChange={(value) => formik.setFieldValue('organizationType', value)}
                                    error={formik.touched.organizationType && Boolean(formik.errors.organizationType)}
                                    helperText={formik.touched.organizationType && formik.errors.organizationType}
                                />
                            </div>

                            <div className='my-2'>
                                <InputBox
                                    inputId="organizationContactNumber"
                                    inputLabel="Organization Contact Number*"
                                    inputName="organizationContactNumber"
                                    inputValue={formik.values.organizationContactNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.organizationContactNumber && Boolean(formik.errors.organizationContactNumber)}
                                    helperText={formik.touched.organizationContactNumber && formik.errors.organizationContactNumber}
                                />
                            </div>
                            <div className='my-2'>
                                <InputBox
                                    inputId="organizationEmail"
                                    inputLabel="organizationEmail*"
                                    inputName="organizationEmail"
                                    inputValue={formik.values.organizationEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.organizationEmail && Boolean(formik.errors.organizationEmail)}
                                    helperText={formik.touched.organizationEmail && formik.errors.organizationEmail}
                                />
                            </div>

                            <div className='my-2'>
                                <InputBox
                                    inputId="website"
                                    inputLabel="Website (Optional)"
                                    inputName="website"
                                    inputValue={formik.values.website}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.website && Boolean(formik.errors.website)}
                                    helperText={formik.touched.website && formik.errors.website}
                                />
                            </div>

                        </div>
                    )}

                    {/* Account Setup Tab */}
                    {activeTab === 1 && (
                        <div className="mt-4">
                             <div className='my-2'>
                                <InputBox
                                    inputId="UserName"
                                    inputLabel="Full Name*"
                                    inputName="UserName"
                                    inputValue={formik.values.UserName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.UserName && Boolean(formik.errors.UserName)}
                                    helperText={formik.touched.UserName && formik.errors.UserName}
                                />
                            </div>
                            <div className='my-2'>
                                <InputBox
                                    inputId="UserEmail"
                                    inputLabel="UserEmail*"
                                    inputName="UserEmail"
                                    inputValue={formik.values.UserEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.UserEmail && Boolean(formik.errors.UserEmail)}
                                    helperText={formik.touched.UserEmail && formik.errors.UserEmail}
                                />
                            </div>
                            <div className='my-2'>
                                <InputBox
                                    inputId="UserPhoneNo"
                                    inputLabel="Contact Number*"
                                    inputName="UserPhoneNo"
                                    inputValue={formik.values.UserPhoneNo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.UserPhoneNo && Boolean(formik.errors.UserPhoneNo)}
                                    helperText={formik.touched.UserPhoneNo && formik.errors.UserPhoneNo}
                                />
                            </div>
                            <div className='my-2'>
                                <PasswordInputBox
                                    inputId="password"
                                    inputLabel="Password*"
                                    inputName="password"
                                    inputValue={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="password"
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </div>


                            <div className='mb-2'>
                                <PasswordInputBox
                                    inputId="confirmPassword"
                                    inputLabel="Confirm Password*"
                                    inputName="confirmPassword"
                                    inputValue={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="password"
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="termsAndConditions"
                                    name="termsAndConditions"
                                    checked={formik.values.termsAndConditions}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label htmlFor="termsAndConditions">
                                    I agree to the terms and conditions of the portal and I provide my consent for the collection and use of my data as described in the privacy policy.
                                </label>
                                {formik.touched.termsAndConditions && formik.errors.termsAndConditions && (
                                    <p className="text-red-500">{formik.errors.termsAndConditions}</p>
                                )}
                            </div>


                        </div>
                    )}

                    {/* Primary Contact Tab */}
                      {formik.errors.organizationName && formik.errors.organizationType && formik.errors.organizationContactNumber && formik.errors.email && formik.errors.password && formik.errors.confirmPassword && formik.errors.termsAndConditions}

                    <div>                    {message && <p className={`${message.status === 'red' ? 'text-red-600' : 'text-green-700'} text-center`}>{message.message}</p>}
                    </div>
                   

                    <p className="text-center text-gray-700">
                        Already have an account? <Link to="/login" className="text-purple-900">Login</Link> here
                    </p>
                </form>
                 <div className="flex justify-end mt-4">
                        {activeTab === 0 ? (
                            <Button  variant="contained" color="primary" onClick={() => setActiveTab(1)}>
                                Next
                            </Button>
                        ) : (
                            <Button type="submit" onClick={formik.handleSubmit} disabled={Loading} variant="contained" color="primary">
                        {Loading ? 'Creating Account...' : 'Create Account'}
                        </Button>
                        )}
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default SignUpForCompany;
