import { useState, useRef } from 'react';

export const useToast = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success'); // Default type is 'success'
  const hideTimer = useRef(null); // Use ref to store the timer

  const showToast = (msg, type = 'success', duration = 3000) => {
    setMessage(msg);
    setType(type);
    setIsVisible(true);

    // Clear any existing timer before setting a new one
    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
    }

    // Set up the timer to auto-hide the toast after the specified duration
    hideTimer.current = setTimeout(() => {
      setIsVisible(false);
    }, duration);
  };

  const pauseHideTimer = () => {
    // Pause by clearing the timer when hovering
    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
    }
  };

  const resumeHideTimer = (duration) => {
    // Resume the timer if the toast is visible
    if (isVisible) {
      hideTimer.current = setTimeout(() => {
        setIsVisible(false);
      }, duration);
    }
  };

  return { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer };
};
