import React from 'react';


const SkillsTable = ({skills=[]}) => {
  
    return (
        <div className="overflow-x-auto bg-gray-100 rounded-lg shadow-lg w-fit">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Skill Name</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Score</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Questions</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Skill Score (%)</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Review</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                    { skills.map((skill, index) => (
                        <tr key={skill.skillId} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">{skill.skillName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{skill.TotalScore}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{skill.TotalQuestions}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{(skill.SkillScore * 100).toFixed(2)}%</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {(() => {
                                    const percentage = skill.SkillScore * 100; // Convert to percentage
                                    let review;

                                    if (percentage > 70) {
                                        review = 'Rockstar';
                                    } else if (percentage > 50) {
                                        review = 'Adequate';
                                    } else {
                                        review = 'Needs Improvement';
                                    }

                                    const reviewClass = review === 'Rockstar'
                                        ? 'bg-green-100 text-green-800'
                                        : review === 'Adequate'
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : 'bg-red-100 text-red-800';

                                    return (
                                        <span
                                            className={`inline-flex px-3 py-1 text-xs font-medium rounded-full ${reviewClass}`}
                                        >
                                            {review}
                                        </span>
                                    );
                                })()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SkillsTable;
