import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, MenuItem, TextField } from '@mui/material';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { CompareSharp } from '@mui/icons-material';
import usePermissions from '../../reusableComp/PermissionCheck';
import StanderdConstants from '../../../services/StanderdConstants';
import { useToast } from '../../../services/useToast';
import Toast from '../../reusableComp/Toast';

function Add({ tableName, pageHeading, IsSuperAdmin, handleclose, setStatus, setRecentformData, formfieldNames = [] }) {
    const [userTypeData, setUserTypeData] = useState([]);
    const [StatusData, setStatusData] = useState([])
    const [formValues, setFormValues] = useState(
        formfieldNames.reduce((acc, element) => {
            acc[element] = '';
            return acc;
        }, {})
    );
    console.log(formfieldNames)
    const [Message, setMessage] = useState('');
    const api_url = process.env.REACT_APP_API_URL;
    const {hasPermission}=usePermissions();
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            tableName,
            data: formValues,
        };
        await sendDataToserver(payload);
    };

    const sendDataToserver = async (formData) => {
        try {
            let apiPath = 'manage/add'
            if (tableName == 'skills') {
                apiPath = 'skills/add'


            }
            const res = await fetch(`${api_url}/${apiPath}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...formData, UserId: StanderdConstants.UserId }),
            });

            const result = await res.json();
            setMessage(result.Message);
            showToast(result.message,result.status)

            if (res.ok) {
                setStatus(true);
                setRecentformData(formData);
            } else {
                console.error('Error while insertion');
            }
        } catch (error) {
            console.error('Network error:', error);
            setMessage('Network error occurred.');
        }
    };
    console.log(tableName)

    useEffect(() => {
        if (tableName == !StanderdConstants.TABLENAME_STATIC_USERTYPE) {
            if (formfieldNames.includes('UserTypeId')) {
                const getUserTypeData = async () => {
                    try {

                        const response = await fetch(`${api_url}/manage/getData`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ tablename: 'staticusertypes' }),
                        });

                        const result = await response.json();
                        if (response.ok) {
                            setUserTypeData(result.data);
                        } else {
                            console.error('Failed to fetch user type data');
                        }
                    } catch (error) {
                        console.error('Error fetching user type data:', error);
                    }
                };
                getUserTypeData();
            }
        }
        if (formfieldNames.includes('Status') || formfieldNames.includes('StatusId')) {
            console.log("here")
            const getStatusData = async () => {
                try {

                    const response = await fetch(`${api_url}/manage/getData`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ tablename: 'staticstatus' }),
                    });

                    const result = await response.json();
                    if (response.ok) {
                        setStatusData(result.data);
                    } else {
                        console.error('Failed to fetch user type data');
                    }
                } catch (error) {
                    console.error('Error fetching user type data:', error);
                }
            };
            getStatusData();
        }

    }, [formfieldNames]); // Keep this dependency

    const showisreserved = (hasPermission('Admin-IsReserved'))
    console.log(showisreserved)
    return (
        <div className='modal-overlay mx-auto'>
            
 <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <h2 className='text-xl text-center'>Add {pageHeading}</h2>
                    <CloseIconButton action={handleclose} />
                </div>
                <form className='flex flex-col px-5 mb-5 gap-10' onSubmit={handleSubmit}>
                    {formfieldNames.map((item, index) => (
                        <div key={index} className='flex flex-col'>
                            {item === 'UserTypeId' ? (

                                <TextField
                                    name={item}
                                    select
                                    value={formValues[item]}
                                    onChange={handleInputChange}
                                    fullWidth
                                    SelectProps={{ native: true }}
                                    variant='standard'
                                >
                                    <option value="" disabled>Select User Type</option>
                                    {userTypeData.length > 0 && userTypeData.map((option) => (
                                        <option key={option.UserTypeId} value={option.UserTypeId}>
                                            {option.UserType}
                                        </option>
                                    ))}
                                </TextField>

                            ) : (
                                <>
                                    {item == 'Status' || item == 'StatusId' ?
                                        (

                                            <TextField
                                                name={item}
                                                select
                                                value={formValues[item] || ''}
                                                onChange={handleInputChange}
                                                fullWidth
                                                SelectProps={{ native: true }}
                                                variant='standard'
                                            >
                                                <option value="" disabled>Status</option>
                                                {StatusData.length > 0 && StatusData.map((option) => (
                                                    <option key={option.StatusId} value={option.StatusId}>
                                                        {option.Status}
                                                    </option>
                                                ))}
                                            </TextField>
                                        ) :
                                        (




                                            <>
                                                {
                                                    (item == 'IsReserved') ?

                                                        (
                                                            showisreserved && <TextField
                                                                name={item}
                                                                select
                                                                value={formValues[item] || ''}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                SelectProps={{ native: true }}
                                                                variant='standard'
                                                            >
                                                                <option value="" disabled>Is Reserved</option>
                                                                <option key={1} value={1}>
                                                                    Reserved
                                                                </option>
                                                                <option key={2} value={0}>
                                                                    Not Reserved
                                                                </option>


                                                            </TextField>
                                                        ) :
                                                        (
                                                            item != 'status' && item != 'StatusId' && item != 'IsReserved' && item != 'UserTypeId' && (item != 'UserType' || tableName == 'staticusertypes') && <InputBox
                                                                required={true}
                                                                inputLabel={item}
                                                                inputId={`add-${item}`}
                                                                onChange={handleInputChange}
                                                                inputValue={formValues[item]}
                                                                type={'text'}
                                                                inputName={item}
                                                            />

                                                        )


                                                }</>

                                        )


                                    }

                                </>


                            )}
                        </div>
                    ))}
                    {Message && <div id='status' className='text-yellow-300'>{Message}</div>}
                    <Button variant='contained' id='Save' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default Add;
