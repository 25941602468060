import { Assessment } from '@mui/icons-material';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ReactSpeedometer from 'react-d3-speedometer';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const TimeAnalysis = ({Data}) => {
  // Data for the bar graph
  const barData = {
    labels: ['Correct Answers', 'Wrong Answers'],
    datasets: [
      {
        label: 'Answers',
        data: [12, 8], // Example data: 12 correct, 8 wrong
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };


  return (
    
      <div className="relative flex flex-col border rounded-lg p-6 shadow-lg w-full bg-gray-50">
        {/* Badge with Profile Icon */}
      <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
        <Assessment className="text-white" fontSize="medium" />
      </div>

        {/* Bar Graph Section */}
        <div className='flex flex-row justify-between'>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Average Time spent</h3>
          <Bar data={barData} options={barOptions} />
        </div>



        {/* Gauge Chart Section */}
        <div>
          {/* <h3 className="text-xl font-semibold mb-2">Average Time Analysis</h3> */}
          {Data.Time/Data.numberOfQuestions}
          <ReactSpeedometer
          maxValue={10}
          needleHeightRatio={0.6}
    value={Data.Time/Data.numberOfQuestions }
    currentValueText="Average Speed"
    customSegmentLabels={[
      {
        text: "Very slow",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "slow",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "Moderate",
        position: "OUTSIDE",
        color: "#555",
        fontSize: "",
      },
      {
        text: "fast",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "Very fast",
        position: "OUTSIDE",
        color: "#555",
      },
    ]}
  />
        
        </div>
        </div>
      
 
    </div>
  );
};

export default TimeAnalysis;
