import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from './Deletecomfirmation';
import usePermissions from '../../reusableComp/PermissionCheck';

const DataTable = ({ UserData = [], handleDelete, handleEditOpen }) => {
    let rowsPerPage = 10
    const [page, setPage] = useState(1);

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };


    // Handle page change
    const handleChange = (event, value) => {
        setPage(value);
    };
    // Calculate the UserData to display based on pagination
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = UserData?.slice(startIndex, endIndex);
    const {hasPermission}=usePermissions();
    useEffect(() => {
        // Ensure the current page is valid when the UserData changes
        const totalPages = Math.ceil(UserData.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages); // Move to the last valid page
        } else if (UserData.length === 0) {
            setPage(1); // Reset to page 1 if no UserData
        }
    }, [UserData, page, rowsPerPage]);

    const ShowActionColumn = (hasPermission('UserRole-Edit') || hasPermission('UserRole-Delete'))
    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="text-gray-50 bg-sky-600">

                                <th className="border p-2 text-start">
                                    User Name
                                </th>

                                <th className="border p-2 text-start">
                                    Role(s)
                                </th>

                                {ShowActionColumn && <th className="border p-2 w-fit">Action</th>
                                }                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((User, index) => (
                                <tr key={index} className="hover:bg-gray-50">

                                    <td className="border p-2 w-2/5">
                                        {User.UserName}
                                    </td>

                                    <td className="border w-2/5 p-2 ">
                                        <span>
                                            {User.Roles.map((item) => item.Role).join(', ')}
                                        </span>
                                    </td>
                                    {ShowActionColumn &&

                                        <td className="border p-2 w-1/5">

                                            <div className="flex justify-around">

                                                {hasPermission('UserRole-Edit') && <EditIconButton action={() => handleEditOpen(User)} />}
                                                {hasPermission('UserRole-Delete') && <DeleteConfirmation action={() => handleDelete(User.UserId)} itemData={User.UserName} />}

                                            </div>
                                        </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(UserData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    );
};


export default DataTable;
