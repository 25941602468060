import React, { useState,useEffect } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import StatsCard from './CommanComponents/StatsCard';

function AdminDashboard() {
const [ChartsData,setChartData]=useState();
const [message,setmessage]=useState();
console.log(ChartsData)

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/AdminDashboard/multiplePlot`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const response = await result.json();
            setChartData(response);


        } catch (error) {
            setmessage(error.message);

        }
    };

    useEffect(() => {
        getdata();
    }, []);
  return (
    <div>
      <StatsOverview data={ChartsData?.MultiplePlote} />
    </div>
  )
}

export default AdminDashboard

function StatsOverview({ data }) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
        <StatsCard
          title="Total Organisations"
          amount={`${data?.TotalOrganisation}`}
          color="green-500"
          Icon="BusinessOutlined" // Represents organizations or businesses
        />
        <StatsCard
          title="Total Candidates Registered"
          amount={`${data?.TotalCandidatesRegistered}`}
          color="blue-500"
          Icon="PeopleAltOutlined" // Represents people or candidates
        />
        <StatsCard
          title="Total Channels Registered"
          amount={`${data?.TotalChannelsRegistered}`}
          color="yellow-500"
          Icon="SubscriptionsOutlined" // Represents channels or subscriptions
        />
      </div>
    );
  }
  


