// components/navigation/Sidebar.js

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, SubMenu, Sidebar } from 'react-pro-sidebar';
import { menuClasses } from 'react-pro-sidebar';
import { Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import * as Icons from '@mui/icons-material';
import logoimage from '../../Logo.png'

const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#0098e5',
      hover: {
        backgroundColor: '#0284c7',
        color: '#FFFFFF',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#0b2948',
      color: '#8ba1b7',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#00458b',
        color: '#FFFFFF',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const SidebarComponent = ({ theme, setTheme, RoutesData, collapsed, setCollapsed, toggled, setToggled, showSidebar }) => {
  const location = useLocation();
console.log(toggled)
const menuItemStyles = {
  root: {
    fontSize: '13px',
    fontWeight: 400,
    zIndex:9999999
   
  },
  icon: {
    color: themes[theme].menu.icon,
    [`&.${menuClasses.disabled}`]: {
      color: themes[theme].menu.disabled.color,
    },
    '&:hover': {
      color: themes[theme].menu.hover.color,
    },
  },
  subMenuContent: ({ level }) => ({
    backgroundColor: hexToRgba(
      themes[theme].menu.menuContent,
      collapsed ? 1 : 0.4
    ), // Fully opaque when collapsed
   
    zIndex: 100000, // Adjust this value if necessary
    position: 'relative', // Ensure submenu positions above other elements

    // Add box shadow to ensure it appears prominent
    boxShadow: collapsed ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
  }),
  button: {
    [`&.${menuClasses.disabled}`]: {
      color: themes[theme].menu.disabled.color,
    },
    '&:hover': {
      backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
      color: themes[theme].menu.hover.color,
      [`& .${menuClasses.icon}`]: {
        color: themes[theme].menu.hover.color,
      },
    },
  },
};


  const getIconComponent = (iconName) => {
    const IconComponent = Icons[iconName];
    return IconComponent ? <IconComponent /> : <Icons.RoundaboutLeft />;
  };

  const handleMenuCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sidebar
    className=''
      collapsedWidth="80px"
      // onBackdropClick={() => setToggled(false)}
      toggled={toggled}
      backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
      // breakPoint="sm"
      collapsed={collapsed}
      rootStyles={{
        color: themes[theme].sidebar.color,
        zIndex:10
      }}
    >
      <Menu menuItemStyles={menuItemStyles}>
        <MenuItem
          onClick={handleMenuCollapsed}
       className=''
          icon={collapsed ? <MenuIcon /> : <Icons.MenuOpen />}
        >
          <div className="hidden">
            <img src={logoimage} alt="logo" className="" />
          </div>
        </MenuItem>

        {RoutesData?.map((parent, index) => (
          parent.children && parent.children.length > 0 ? (
            <SubMenu
              key={index}
              label={parent.Menu}
              icon={getIconComponent(parent.Icon)}
              title={parent.Menu}
              style={{zIndex:100000}}
            >
              {parent.children.map((childMenu) => (
                <MenuItem
                  key={childMenu.MenuId}
                  component={<Link to={`/${childMenu.RoutePath}`} />}
                  icon={getIconComponent(childMenu.Icon)}
                  title={childMenu.Menu}
                  style={{zIndexd:10000}}
                >
                  {childMenu.Menu}
                </MenuItem>
              ))}
            </SubMenu>
          ) : (
            <MenuItem
              key={index}
              component={<Link to={`/${parent.RoutePath}`} />}
              icon={getIconComponent(parent.Icon)}
              title={parent.Menu}
            >
              {parent.Menu}
            </MenuItem>
          )
        ))}
      </Menu>
      <div style={{ margin: '16px 0' }}>
        <Switch checked={theme === 'dark'} onChange={(e) => setTheme(e.target.checked ? 'dark' : 'light')} />
        <label style={{ marginLeft: '8px' }}>Dark Theme</label>
      </div>
    </Sidebar>
  );
};

export default SidebarComponent;
