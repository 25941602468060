import React, { useState } from 'react';
import { Autocomplete, Checkbox, TextField, Chip } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

export function KeywordInput({
    multiple = true,
    dataText = 'Keyword',
    dataField = 'KeywordId', // The field representing the unique ID
    inputValue,
    inputName,
    onChange,
    inputLabel,
    inputId
}) {
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    // Fetch suggestions from the server
    const fetchSuggestions = async (query) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/getKeywords`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ searchQuery: query })
            });
            const data = await response.json();
            setSuggestions(data.data || []);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    // Save a new keyword to the server
    const saveKeyword = async (newKeyword) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/addKeyword`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ keyword: newKeyword })
            });
            const data = await response.json();
            return data; // Assuming the API returns an object with KeywordId and Keyword
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    };

    // Handle selection change
    const handleOnChange = async (event, value) => {
        console.log(event,value)
        const lastValue = value[value.length - 1];
        // If the last value is a string, it's a new custom keyword
        if (typeof lastValue === 'string' && !suggestions.find(item => item[dataText] === lastValue)) {
            const newKeywordData = await saveKeyword(lastValue);
            console.log(newKeywordData)  
            // newKeywordData.data will give id of newly inserted keyowrd
            if (newKeywordData) {
                value[value.length - 1] = {
                    [dataField]: newKeywordData.data, // Use the ID from the response
                    [dataText]: lastValue,   // Use the keyword text from the response
                };            }
        }
        // Send both KeywordId and Keyword to the parent component
        onChange(event, value);
    };

    return (
        <Autocomplete
            id={inputId}
            multiple={multiple}
            options={suggestions}
            value={inputValue}
            freeSolo
            disableCloseOnSelect
            loading={loading}
            getOptionLabel={(option) => typeof option === 'string' ? option : option[dataText] || ''} // Display the text for each option
            isOptionEqualToValue={(option, value) => option[dataField] === value[dataField]} // Compare by unique field
            onChange={handleOnChange} // Handle selection changes
            onInputChange={(event, value) => {
                if (value) {
                    fetchSuggestions(value);
                }
            }}
            renderOption={(props, option, { selected }, index) => (
                <li {...props} key={index}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {typeof option === 'string' ? option : option[dataText]}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={typeof option === 'string' ? option : option[dataText]}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    variant="standard"
                    placeholder="Type to search or add..."
                />
            )}
        />
    );
}
