import React, { useState } from 'react'
// import './App.css'
import { Button, IconButton, TextField } from '@mui/material';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import StanderdConstants from '../../services/StanderdConstants';
function AddCandidateModal({ handleclose, setStatus, IsSuperAdmin, setRecentformData }) {
    const [message, setmessage] = useState('')
    const api_url = process.env.REACT_APP_API_URL
    const [formData, setFormData] = useState({
        jobroleName: '',
        jobroleDescription: '',
        IsReserved: 0,
        UserId: StanderdConstants.UserId
    })
    console.log(formData)
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handlesubmit = (e) => {

        e.preventDefault()
        console.log(formData)
        sendDataToserver(formData)

    }

    const sendDataToserver = async (formData) => {
        const res = await fetch(`${api_url}/jobroles/add`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        const result = await res.json();
        console.log(result)
        setmessage(result.message)
        if (res.ok) {
            console.log("data inserted")
            setStatus(true)
            setRecentformData(formData)

        }
        else {
            console.log("error while insertion")

        }


    }
    console.log(message)



    return (
        <div className='modal-overlayy'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Job Role"} />

                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={handlesubmit}>
                    <div className='flex flex-col'>
                        <InputBox
                            required='true'
                            inputId='addJobroleName'
                            type='text'
                            inputName='jobroleName'
                            onChange={handleChange}
                            inputValue={formData.jobroleName}
                            inputLabel='Jobrole'
                        />


                    </div>
                    {IsSuperAdmin && (<div className='flex flex-col'>

                        <CustomSelect
                            options={[{ value: 1, label: 'yes' }, { value: 2, label: 'no' }]}  // options array
                            dataField='value'   // Key for the option value
                            dataText='label'    // Correct the key from 'lable' to 'label' for displaying text
                            label='IsReserved'  // Label for the select input
                            name='IsReserved'   // Name of the input field
                            onChange={(e) => handleChange(e)}  // Change event handler
                            value={formData.IsReserved}  // Current value from formData
                        />
                    </div>)}
                    <div className='flex flex-col'>
                        <InputBox
                            required='true'
                            inputLabel='Description'
                            inputId='addJobroleDesc'
                            inputName='jobroleDescription'
                            onChange={handleChange}
                            inputValue={formData.jobroleDescription}
                        />
                    </div>
                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button variant='contained' className='mt-4' id='addJobrole' type='submit'>
                        Save Job Role
                    </Button>
                </form>
            </div>

        </div >
    )
}

export default AddCandidateModal
