import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import Heading from "../reusableComp/Heading";
import { InputBox } from "../../ButtonsIconsInputs/CustomInputs";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation

const RequestPasswordReset = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required*"),
    }),
    onSubmit: async (values) => {
      setMessage(""); // Clear any previous messages
    setLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/ResetPassword/request-password-reset`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ Email: values.email }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setMessage(data.message);
          setLoading(false)
        } else {
            setLoading(false)
          setMessage(data.message || "Error requesting password reset.");
        }
      } catch (error) {
        setLoading(false)
        console.log(error);
        setMessage("Error requesting password reset.");
      }
    },
  });

  return (
    <div className="flex justify-center min-h-screen w-full bg-gray-100">
      <div className="flex flex-col my-5 w-full max-w-md">
        <div className="w-full bg-sky-600 text-gray-50 rounded-t-md">
          <h1 className="text-2xl text-center p-2">Wise Assessment</h1>
          <Divider orientation="horizontal" />
        </div>
        <form
          className="flex flex-col gap-6 border border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full"
          onSubmit={formik.handleSubmit}
        >
          <Heading heading=" Password Reset Request  " />

          <InputBox
            inputId="email"
            inputLabel="Email*"
            inputName="email"
            inputValue={formik.values.email}
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          {message && (
            <p className="mt-4 text-center text-gray-700">{message}</p>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            size="large"
            className="w-full mt-4"
          >
            Request Reset Link
          </Button>

          <p className="text-center text-gray-700 mt-4">
            <span className="mx-2">
              Remember your password?{" "}
              <Link to="/login" className="text-blue-900">
                Login
              </Link>
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
