import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Heading from '../../reusableComp/Heading';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, CustomSelectv4, InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import './App.css';
import SubscriptionPlanInfo from '../../reusableComp/SubscriptionPlanInfo';
import { getAccessOptions } from '../../reusableComp/IsOrganisation';
import { KeywordInput } from '../../reusableComp/KeywordInput';
import useUserId from '../../../services/useUserId';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';

function EditModal({ handleClose, setStatus, Data }) {
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [Message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [accessOptions, setAccessOptions] = useState([]);

    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();


    const UserId=useUserId()
    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };
    console.log(selectedKeywords)
    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(UserId); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }
        fetchAccessOptions();
    }, []);
    const formik = useFormik({
        initialValues: {
            assessmentIdArray: '',
            SeriesId: '',
            SeriesName: '',
            DateValidFrom: '',
            DateValidTo: '',
            SeriesImage: '',
            Visibility: ''


        },

        onSubmit: values => {
            saveKeywordIntodb(Data.AssessmentSeriesId)
            console.log(values);
            const formData = new FormData();
            formData.append('AssessmentSeriesId', Data.AssessmentSeriesId)
            formData.append('assessmentIdArray', values.assessmentIdArray)
            formData.append('SeriesName', values.SeriesName)
            formData.append('DateValidFrom', values.DateValidFrom)
            formData.append('DateValidTo', values.DateValidTo)
            formData.append('SeriesImage', values.SeriesImage)
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            formData.append('SeriesVisibility', values.Visibility)

            sendDataToServer(formData);
        }
    });

    console.log(Data)
    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/SaveIntoDbEdit`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SeriesId: Id, KeyWords: selectedKeywords })
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Keyword Added")
            }
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }

    const getAssessments = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const sendDataToServer = async (data) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/Edit`, {
                method: 'post',
                body: data
            });
            const response = await result.json();
            showToast(response.message,response.status)

            if (result.ok) {
                setStatus(true);
                setMessage(response.Message);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        if (Data) {
            const Idarray = Data.assessments?.filter(item => item.AssessmentId !== null).map(item => item.AssessmentId);
            formik.setValues({
                SeriesName: Data.AssessmentSeriesName || '',  // Assign empty string if SeriesName is null
                SeriesId: Data.AssessmentSeriesId || '',      // Assign empty string if SeriesId is null
                assessmentIdArray: Idarray.length > 0 ? Idarray : [],  // Only assign Idarray if it has values
                DateValidFrom: Data.DateValidFrom,
                DateValidTo: Data.DateValidTo,
                Visibility: Data.Visibility
            });
 
            const keywordsArray = Data?.Keywords?.split(',').map((keyword, index) => ({
                KeywordId: parseInt(Data?.KeywordId?.split(',')[index], 10), 
                Keyword: keyword?.trim() 
            }));
    
            // Step 2: Set the state with the formatted array
            console.log(keywordsArray)
            setSelectedKeywords(keywordsArray || []);

        }
    }, [Data]);

    console.log(formik.values.assessmentIdArray)
    // Handle drag-and-drop reordering

    console.log(`${process.env.REACT_APP_API_URL}/AssessmentImages/${Data.SeriesImage}`)
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(formik.values.assessmentIdArray);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        formik.setFieldValue('assessmentIdArray', items);
    };

    const handleFileChange = (event) => {
        formik.setFieldValue('SeriesImage', event.currentTarget.files[0]);
    };

    return (
        <div className='modal-overlayy flex justify-center items-center fixed inset-0 z-50 bg-black bg-opacity-50 overflow-auto'>
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className='modal-contentt bg-white rounded-lg shadow-lg w-full max-w-4xl '>
                <div className='flex justify-between items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4 rounded-t-lg'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit assessment Series"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                {/* Modal body */}
                <form onSubmit={formik.handleSubmit} className='flex flex-col p-6 gap-4 overflow-auto h-auto max-h-[90vh]'>
                    <div className='w-full'>
                        <InputBox
                            inputId='seriesName'
                            inputLabel='SeriesName'
                            inputName='SeriesName'
                            inputValue={formik.values.SeriesName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>
                    <div>
                        <div>
                            <label htmlFor="DateValidFrom">Date Valid From:</label>
                            <input
                                type="date"
                                id="DateValidFrom"
                                name="DateValidFrom"
                                value={formik.values.DateValidFrom}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div style={{ marginTop: 16 }}>
                            <label htmlFor="DateValidTo">Date Valid To:</label>
                            <input
                                type="date"
                                id="DateValidTo"
                                name="DateValidTo"
                                value={formik.values.DateValidTo}
                                onChange={formik.handleChange}
                            />
                        </div>

                    </div>

                    <div className="form-group">
                        <label htmlFor="add-CompanyLogo" className=''>New Series Images</label>
                        <input
                            id="add-seriesimage"
                            name="SeriesImage"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />

                    </div>

                    {!formik.values.SeriesImage && Data.SeriesImage && (
                        <div className=' flex flex-row items-center mx-auto justify-center rounded-sm h-40 w-40'>
                            <img alt='Seriesbanner' src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${Data.SeriesImage}`} />
                        </div>
                    )}

                    <div>
                        <CustomSelectv4
                            options={accessOptions || []}
                            dataField="value"
                            dataText="label"
                            inputId="Visibility"
                            label="Series Visibility"
                            name="Visibility"
                            onChange={(selectedOption) => formik.setFieldValue("Visibility", selectedOption)}
                            value={formik.values.Visibility || ''}
                        />
                    </div>
                    <div>
                        <SubscriptionPlanInfo
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                            selectedCurrency={selectedCurrency}
                            setSelectedCurrency={setSelectedCurrency}
                            subscriptionAmount={subscriptionAmount}
                            setSubscriptionAmount={setSubscriptionAmount}
                        />
                    </div>
                    <div className='my-2'>
                        <CustomSelectv2
                            options={AssessmentList || []}
                            dataField="assessmentId"
                            dataText="nameOfAssessment"
                            inputId="selectassessment"
                            label="Select Assessment"
                            name="assessmentIdArray"
                            multiple
                            onChange={(selectedOptions) => {
                                formik.setFieldValue("assessmentIdArray", selectedOptions);
                            }}
                            value={formik.values.assessmentIdArray || []}
                        />
                    </div>

                    {/* Drag-and-drop section */}
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="assessmentList">
                            {(provided) => (
                                <ul className="assessment-list" {...provided.droppableProps} ref={provided.innerRef}>
                                    {formik.values.assessmentIdArray && formik.values.assessmentIdArray.length > 0 ? (
                                        formik.values.assessmentIdArray.map((item, index) => (
                                            <Draggable key={item} draggableId={String(item)} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="bg-white rounded-lg shadow-md p-4 mb-3 flex justify-between items-center border border-gray-200"
                                                    >
                                                        <span className="font-semibold text-gray-700">
                                                            {' '} ({index + 1})  {AssessmentList.find(assessment => assessment.assessmentId == item)?.nameOfAssessment || 'Unknown Assessment'}
                                                        </span>
                                                        <span className="text-blue-500 font-medium cursor-pointer">
                                                            Drag
                                                        </span>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))
                                    ) : (
                                        <p className="text-gray-500">No assessments available to display.</p>
                                    )}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className='mb-4'>

                        <KeywordInput
                            multiple={true}
                            dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                            inputValue={selectedKeywords} // Pass the state as value
                            inputName="keywords"
                            onChange={handleKeywordsChange} // Pass the handler function
                            inputLabel="Keywords"
                            inputId="keywords-input"
                        />

                    </div>

                    {Message && <p className="text-red-500">{Message}</p>}



                    <Button type="submit" disabled={loading} variant='contained'>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>

                </form>
            </div>
        </div>

    );
}

export default EditModal;
