import React from 'react';

const AnswerList = ({ answers }) => {
    return (
        <div className="answers-list mt-2">
            <ul className="list-disc pl-5">
                {answers && answers.map(answer => (
                    <li key={answer.FreeTextAnswerId} className="mt-1">
                       
                        {answer.answer_image ? <img src={`${process.env.REACT_APP_API_URL}/${answer.answerImage}`} alt="Answer" className="inline-block mr-2" /> : answer.answerText}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AnswerList;
