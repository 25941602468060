import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconButton } from '@mui/material';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import Heading from '../../reusableComp/Heading';
import { Cancel, Edit } from '@mui/icons-material';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';

function ManageCredentials() {
    const [editPassword, setEditPassword] = useState(false);
    const [Message, setMessage] = useState('')
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    // Formik form setup for password fields
    const handleSubmit = async (values) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/change-password/${localStorage.getItem('userId')}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                }),
            });

            const data = await response.json();

            showToast(data.message,data.status)
            if (!response.ok) {
                throw new Error(data.Message || 'An error occurred while updating the password.');
            }
            setMessage(data.Message)

        } catch (error) {
            console.error('Error updating password:', error);
           
        }
    };

    // In the Formik setup
    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string().required('Current password is required'),
            newPassword: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .required('New password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Confirm password is required'),
        }),
        onSubmit: handleSubmit,
    });


    return (
        <div className="max-w-[45rem] mx-auto">
           
       
        <div className="p-6 w-full  border bg-white shadow-md rounded-lg">
        <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className="mb-4 flex flex-row justify-between">
                <p className="text-gray-600 mb-4">Do you want to update your password?</p>
                <IconButton
                    onClick={() => setEditPassword(!editPassword)}
                    variant="contained"
                    className="mt-2 bg-blue-500 text-white"
                >
                    {editPassword ? <Cancel color='warning'/> : <Edit color='primary'/>}
                </IconButton>
            </div>
    
            {editPassword && (
                <form onSubmit={formik.handleSubmit} className="space-y-4">
                    <InputBox
                        inputId="currentPassword"
                        inputLabel="Current Password"
                        inputName="currentPassword"
                        type="password"
                        inputValue={formik.values.currentPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                        helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                    />
    
                    <InputBox
                        inputId="newPassword"
                        inputLabel="New Password"
                        inputName="newPassword"
                        type="password"
                        inputValue={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                    />
    
                    <InputBox
                        inputId="confirmPassword"
                        inputLabel="Confirm New Password"
                        inputName="confirmPassword"
                        type="password"
                        inputValue={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    />
                    
                    {Message && <p className="text-red-500">{Message}</p>}
                    
                    <Button
                        type="submit"
                        variant="contained"
                        className="w-full mt-4 bg-blue-500 text-white py-2 rounded-md hover:bg-sky-600"
                    >
                        Update Password
                    </Button>
                </form>
            )}
        </div>
    </div>
    
    );
}

export default ManageCredentials;
