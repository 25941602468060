import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'
import CircularProgress from '@mui/material/CircularProgress';
import StanderdConstants from '../../services/StanderdConstants';


function ManageUsers() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings


    console.log(insertionStatus)


    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData   `, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: StanderdConstants.USERS_TABLE_NAME })

        })
        const result = await response.json();
        if (response.ok) {
            setData(result.data)
            setdatakeys(Object.keys(result.data[0]).slice(1))

        }
    }
    console.log(Data)
    useEffect(() => {
        getData()
    }, [])

    const columns = [
        { header: StanderdConstants.COLUMN_DBTABLE_USER_NAME, field: StanderdConstants.COLUMN_DBTABLE_USER_NAME },
        { header: StanderdConstants.COLUMN_DBTABLE_USER_EMAIL, field: StanderdConstants.COLUMN_DBTABLE_USER_EMAIL },
        { header: StanderdConstants.COLUMN_DBTABLE_USER_PHONE, field: StanderdConstants.COLUMN_DBTABLE_USER_PHONE },
        { header: StanderdConstants.HEADER_USER_TYPE, field: StanderdConstants.COLUMN_DBTABLE_USER_TYPE },
        { header: StanderdConstants.COLUMN_DBTABLE_USER_STATUS, field: StanderdConstants.COLUMN_DBTABLE_USER_STATUS }
    ];

    return (
        <div>
            {
                Data?.length && datakeys?.length > 0
                    ? <ManageModule
                        pageHeading='Users'
                        permissionprefix='User'
                        data={Data}
                        tableHeadings={['Name', 'Email', 'Phone', 'UserTypeId', 'Status']}  // can give table heading  name in array too eg ['h1','h5']
                        tableName={StanderdConstants.USERS_TABLE_NAME}
                        formfieldNames={['Name', 'Email', 'Phone', 'UserTypeId', 'Status']} // can gives fields name in array too. eg. ['column1','column5']
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}
                    />
                    :
                    <center ><CircularProgress /></center>
            }



        </div>
    )
}

export default ManageUsers
