import { createTheme, ThemeProvider } from "@mui/material/styles";

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Ensure this file exists
import App from './App'; // Ensure App component is correctly exported
import reportWebVitals from './reportWebVitals';
import Main from "./Main";
import { BrowserRouter } from "react-router-dom";

// Optional: Customize the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize primary color
    },
    secondary: {
      main: '#dc004e', // Customize secondary color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Customize font family
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>


);

// Measure performance (optional)
reportWebVitals();
