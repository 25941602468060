import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { InputWithDropDownv2 } from "../../../ButtonsIconsInputs/CustomInputs";

function UserAndCompanyDropDown({ setSelectedUser, setSelectedCompany,selectedUser,selectedCompany }) {
  const [usersData, setUsersData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [message, setMessage] = useState("");
  
    
  const GetUserAndCompaniesData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/users/UserAndCompanyData`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      setUsersData(response.UsersData);
      setCompaniesData(response.CompanyData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  useEffect(() => {
    GetUserAndCompaniesData();
  }, []);

 

  const handleUserChange = (event, value) => {
    console.log(value)
    setSelectedUser(value.UserId);
    setSelectedCompany(null);
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value?.CompanyId);
    setSelectedUser(null);
  };



  return (
    <div>
      {message && <p className="text-red-500">{message}</p>}
      <div className="mb-4 -mt-2">
        <Autocomplete
          id="user-dropdown"
          options={usersData}
          value={usersData.find(item=>item?.UserId===selectedUser)} // Use ChoosenUser state for controlled value
          getOptionLabel={(option) => option.Name || ""}
          isOptionEqualToValue={(option, value) => option.Id === value.Id}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="standard" />
          )}

          renderOption={(props, option,) => (
            <li {...props}>
              
              <div className="flex flex-row gap-1">
                <strong>{option.Name}</strong>
                
                <small style={{ color: "gray" }}>({option.Email})</small>
              </div>
            </li>
          )}
        />
      </div>
      <div className="mt-[2rem]" >
        <InputWithDropDownv2
          data={companiesData || []}
          dataField="CompanyId"
          dataText="CompanyName"
          inputId="ChooseCompany"
          inputLabel="Select Company"
          inputName="selectedCompany"
          multiple={false}
          onChange={(event, newValue) => handleCompanyChange(newValue)}
          inputValue={companiesData.find(item => item?.CompanyId === selectedCompany) || {}} // Use ChoosenCompany state for controlled value
        />
      </div>
    </div>
  );
}

export default UserAndCompanyDropDown;
