import React from 'react'
import MyResponsiveLine from './CommanComponents/linechar';

function CandidateDash() {
    const data = [
        {
            id: 'Your Score',
            data: [
                { x: 'Assessment 1', y: 85 },
                { x: 'Assessment 2', y: 90 },
                { x: 'Assessment 3', y: 78 },
                { x: 'Assessment 4', y: 92 },
                { x: 'Assessment 5', y: 88 },
            ],
        },
        {
            id: 'Average Score',
            data: [
                { x: 'Assessment 1', y: 80 },
                { x: 'Assessment 2', y: 85 },
                { x: 'Assessment 3', y: 75 },
                { x: 'Assessment 4', y: 90 },
                { x: 'Assessment 5', y: 82 },
            ],
        },
    ];

    return (
        <div>
            <div style={{ height: '400px' }}>
                <MyResponsiveLine data={data} />
            </div>
        </div>
    )
}

export default CandidateDash