import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';
import { hasPermission } from '../reusableComp/PermissionCheck';
import StanderdConstants from '../../services/StanderdConstants';

export default function AutoCompletecompetency({ oncompetencyselect }) {
    const [competency, setcompetencyData] = useState([]);
    const [currentcompetency, setcurrentcompetency] = useState(null)
    const api_url = process.env.REACT_APP_API_URL
    const getcompetencyDetails = async () => {
        let result = await fetch(`${api_url}/competencies/competency`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ UserId: StanderdConstants.StanderdConst })
        });
        result = await result.json();
        setcompetencyData(result.data);
    }

    useEffect(() => {
        getcompetencyDetails();
    }, []);

    const newArray = competency.map(competency => ({
        label: competency.competencyName,
        ...competency
    }));

    return (
        <div className='flex flex-row w-full  gap-2'>




            <InputWithDropDown
                className='w-4/6'
                data={newArray}
                dataField={'competencyId'}
                dataText={'competencyName'}
                inputId={'competencydropdown'}
                inputLabel={'competencies'}
                inputName={'competency'}
                onChange={(event, value) => setcurrentcompetency(value)}  // Update setcurrentskill with the selected value
                inputValue={currentcompetency}

            />
            <Button className='w-2/6 border-none  h-auto  text-gray-50 font-bold' sx={{ backgroundColor: '#0284c7' }} variant='contained' onClick={() => oncompetencyselect(currentcompetency)} > + Add</Button>
        </div>

    );
}
