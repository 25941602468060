import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'
import CircularProgress from '@mui/material/CircularProgress';
import StanderdConstants from '../../services/StanderdConstants';


function ManageSkills() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings

    console.log(insertionStatus)


    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/skills/   `, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ UserId: StanderdConstants.UserId })

        })
        const result = await response.json();
        if (response.ok) {
            setData(result.data)
            setdatakeys(Object.keys(result.data[0]).slice(1))

        }
    }
    console.log(Data)
    useEffect(() => {
        getData()
    }, [])
    const columns = [
        { header: 'skill', field: 'skillName' },
        { header: 'skill Description', field: 'skillDescription' },


    ];
    return (
        <div>
            {
                Data?.length && datakeys?.length > 0
                    ? <ManageModule
                        pageHeading='Skills'
                        permissionprefix='Skill'
                        data={Data}
                        tableHeadings={datakeys}
                        tableName={StanderdConstants.TABLENAME_SKILLS}
                        formfieldNames={['skillName', "skillDescription", "IsReserved"]}
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}

                    />
                    :
                    <center ><CircularProgress /></center>
            }



        </div>
    )
}

export default ManageSkills
