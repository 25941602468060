import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography } from '@mui/material';
import { ExpandCircleDown } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Icons from '@mui/icons-material';

// Utility function to reorder items in the list
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const DataTable = ({ actions, data, onDragEnd, setStatus }) => {
    const [rowsPerPage] = useState(10000);
    const [page, setPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const swapMenuSequence = async (swappedIds) => {
        try {

            const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/swapMenuOrder`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(swappedIds)
            });
            const response = await result.json();
            if (result.ok) {
                console.log(response)

            }
        } catch (error) {
            console.error('Error fetching menu list:', error);
        }
    };




    useEffect(() => {
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setPaginatedData(data.slice(startIndex, endIndex));

        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        } else if (data.length === 0) {
            setPage(1);
        }
    }, [data, page, rowsPerPage]);

    // Handling the drag end event
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const updatedData = reorder(paginatedData, result.source.index, result.destination.index);
        setPaginatedData(updatedData);
        const sourceItemId = paginatedData[result.source.index].MenuId;
        const destinationItemId = paginatedData[result.destination.index].MenuId;

        const swapableIds = { id1: sourceItemId, id2: destinationItemId }
        swapMenuSequence(swapableIds)
        if (onDragEnd) {
            onDragEnd(result.source.index, result.destination.index);
        }
    };

    const getIconComponent = (iconName) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent /> : <Icons.RoundaboutLeft />;
    };

    return (
        <div className="overflow-x-auto">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-table">
                    {(provided) => (
                        <table className="w-full border-collapse border border-gray-200" {...provided.droppableProps} ref={provided.innerRef}>
                            <thead>
                                <tr className="text-gray-50 bg-sky-600">
                                    <th className="border p-2 text-start w-full">Menu Name</th>
                                    <th className="border p-2 w-fit">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((item, index) => (
                                    <Draggable key={item.id || index} draggableId={item.id ? item.id.toString() : index.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`hover:bg-gray-50 border ${snapshot.isDragging ? 'bg-gray-200' : ''}`}
                                            >
                                                {/* Render Parent Menu with Children in an Accordion */}
                                                <td colSpan={1}>
                                                    {item.children && item.children.length > 0 ? (
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandCircleDown />}
                                                                aria-controls={`panel-${index}-content`}
                                                                id={`panel-${index}-header`}
                                                                className='px-2'

                                                            >
                                                                <span className=' pr-2'>{getIconComponent(item.Icon)}</span>

                                                                <Typography> {item.Menu}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <List component="div" disablePadding >
                                                                    {item.children.map((childMenu, index) => (
                                                                        <ListItem key={childMenu.MenuId} className='px-2' >
                                                                            <span>{getIconComponent(childMenu.Icon)}</span>
                                                                            <Typography className="pl-4"> {childMenu.Menu}</Typography>
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ) : (
                                                        <div className="flex items-center px-2">
                                                            <span>{getIconComponent(item.Icon)}</span>

                                                            <Typography className="pl-4"> {item.Menu}</Typography>
                                                        </div>
                                                    )}
                                                </td>

                                                {/* Render Actions if Available */}
                                                {actions?.length > 0 && (
                                                    <td className="border p-2 w-fit">
                                                        <div className="flex justify-around">
                                                            {actions.map((action, actionIndex) => {
                                                                const ButtonComponent = action.ButtonComponent;
                                                                return (
                                                                    <ButtonComponent
                                                                        key={actionIndex}
                                                                        action={() => action.handler(item, index)}
                                                                        tooltip={action.tooltip}
                                                                        id={index}
                                                                        itemName={item.Menu}
                                                                        itemData={item}
                                                                        label={action.label}
                                                                        className={action.className}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>

            {/* Pagination Controls */}

        </div>
    );
};

export default DataTable;
