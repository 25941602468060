import React, { useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants'
import MyResponsiveBar from './CommanComponents/MyResponsiveBar';
import DonutChartsRow from './CommanComponents/DonutChart';

function AuthorDash() {
    const [AssessmentData, setAssessmentData] = useState([])
    const [message, setmessage] = useState([])


    const assessmentData = [
        { assessmentId: '101', nameOfAssessment: 'Research Methods', numberOfStudents: 40 },
        { assessmentId: '102', nameOfAssessment: 'Data Science', numberOfStudents: 35 },
        { assessmentId: '103', nameOfAssessment: 'Machine Learning', numberOfStudents: 50 },
        { assessmentId: '104', nameOfAssessment: 'Web Development', numberOfStudents: 45 },
        { assessmentId: '105', nameOfAssessment: 'Cyber Security', numberOfStudents: 30 },
        { assessmentId: '106', nameOfAssessment: 'Software Testing', numberOfStudents: 25 },
        { assessmentId: '107', nameOfAssessment: 'Business Analytics', numberOfStudents: 60 },
        { assessmentId: '108', nameOfAssessment: 'Cloud Computing', numberOfStudents: 55 },
        { assessmentId: '109', nameOfAssessment: 'Artificial Intelligence', numberOfStudents: 48 },
        { assessmentId: '110', nameOfAssessment: 'Big Data Analytics', numberOfStudents: 38 }
    ];

    const getAssessmentData = async () => {

        const res = await fetch(`${process.env.REACT_APP_API_URL}/authorDash/assessmentDetails`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ UserId: StanderdConstants.UserId })

        })
        const result = await res.json()
        if (res.ok) {
            setAssessmentData(result.data)
        }
        else {
            setmessage(result.message)
        }

    }

    const assessmentDataa = [
        { assessmentId: '101', nameOfAssessment: 'Research Methods', numberOfStudents: 40 },
        { assessmentId: '102', nameOfAssessment: 'Data Science', numberOfStudents: 35 },
        { assessmentId: '103', nameOfAssessment: 'Machine Learning', numberOfStudents: 50 },
        { assessmentId: '104', nameOfAssessment: 'Web Development', numberOfStudents: 45 },
        { assessmentId: '105', nameOfAssessment: 'Cyber Security', numberOfStudents: 30 },
        { assessmentId: '106', nameOfAssessment: 'Software Testing', numberOfStudents: 25 },
        { assessmentId: '107', nameOfAssessment: 'Business Analytics', numberOfStudents: 60 },
        { assessmentId: '108', nameOfAssessment: 'Cloud Computing', numberOfStudents: 55 },
        { assessmentId: '109', nameOfAssessment: 'Artificial Intelligence', numberOfStudents: 48 },
        { assessmentId: '110', nameOfAssessment: 'Big Data Analytics', numberOfStudents: 38 }
    ];

    const Chartdata = [
        { nameOfAssessment: 'Research Methods', score: 8, total: 10 },
        { nameOfAssessment: 'Data Science', score: 9, total: 10 },
        { nameOfAssessment: 'Machine Learning', score: 6, total: 10 },
        { nameOfAssessment: 'Software Testing', score: 8, total: 10 },
        { nameOfAssessment: 'Big Data Analytics', score: 7, total: 10 },
    ];

    // Render MyResponsiveBar component with assessmentData

    return (
        <div className='h-80 flex flex-col gap-5'>
            <MyResponsiveBar data={assessmentDataa} />
            <div className='h-80'> <DonutChartsRow Chartdata={Chartdata} /></div>

        </div>
    )
}

export default AuthorDash