import React, { useEffect, useState, useRef } from 'react';
import CandidateDetailsCard from './CandidateDetailsCard';
import OverallResult from './OverallResult';
import TimeAnalysis from './TimeAnalysis';
import SectionWisePerformance from './SectionWisePerformance';
import OverAllSummery from './OverAllSummery';
import { useLocation } from 'react-router-dom';
import { Download } from '@mui/icons-material';
import { Button, Skeleton } from '@mui/material';
import ReactToPrint from 'react-to-print';

function Report() {
  const [BasicDetails, setBasicDetails] = useState(null); // Set initial state to null
  const [SkillData,SetSkillData]=useState([])
  const location = useLocation();
  const AssessmentAttemptId=location.state.data
  const componentRef = useRef();

  const getScoreData = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 5000));
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ScoreCard/Report`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: AssessmentAttemptId })
      });
      const result = await response.json();
      if (response.ok) {
        setBasicDetails(result.Details);
        SetSkillData(result.SkillData)
      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };

  useEffect(() => {
    getScoreData();
  }, []);

  return (
    <div className="bg-gray-100 flex flex-col gap-3">
      <div className="bg-gray-50 h-10 py-2 flex justify-between px-4">
        <span>Candidate's Assessment Report</span>
        <ReactToPrint
          trigger={() => (
            <Button variant="text">
              <Download /> Download Detailed Report
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <div className="p-4 grid grid-cols-1 lg:grid-cols-4 gap-10" ref={componentRef}>
        {/* Conditionally render Skeleton loader or actual content */}
        {BasicDetails ? (
          <>
            <div className="lg:col-span-1 min-h-2/6">
              <CandidateDetailsCard Data={BasicDetails} />
            </div>
            <div className="lg:col-span-3 min-h-2/6">
              <OverallResult Data={BasicDetails} aaid={AssessmentAttemptId} />
            </div>
            <div className="lg:col-span-4 h-2/6 print:mt-10">
              <TimeAnalysis Data={BasicDetails} />
            </div>
            <div className="lg:col-span-4 h-2/6 print:mt-5 print:mb-5">
              <SectionWisePerformance AssessmentAttemptId={AssessmentAttemptId} />
            </div>
            <div className="lg:col-span-4 h-2/6 print:mt-5 ">
              <OverAllSummery Data={SkillData} />
            </div>
          </>
        ) : (
          // Render Skeletons if BasicDetails is not loaded
          <>
            <div className="lg:col-span-1 min-h-2/6">
              <Skeleton variant="rectangular" height={200} />
            </div>
            <div className="lg:col-span-3 min-h-2/6">
              <Skeleton variant="rectangular" height={200} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Report;
