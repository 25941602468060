import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox, InputWithDropDownv2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import StanderdConstants from '../../../services/StanderdConstants';

function EditModal({ handleClose, setStatus, Data }) {
    const [UsersList, SetUsersList] = useState([]);

    // get Company Name
    async function checkIfUserBelongsToOrganization() {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/companies/IsUserBelongsToOrganisation/?Id=${StanderdConstants.UserId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!res.ok) {
                // Handle non-200 status codes
                return false;
            }

            const result = await res.json();
            console.log(result)

            if (result && result.company) {
                console.log(result)
                formik.setFieldValue('CompanyId', result.CompanyId)
                formik.setFieldValue('CompanyName', result.company)
            } else {
                return false;
            }

        } catch (error) {
            // Handle network or unexpected errors
            console.error('Error fetching organization data:', error);
            return false;
        }
    }
    const getData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData   `, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_STATICROLES })

        })
        const result = await response.json();
        if (response.ok) {

        }
    }
    console.log(Data)
    useEffect(() => {
        getData()

    }, [])
    useEffect(() => {
        // GetPeopleWithoutCompany();
        checkIfUserBelongsToOrganization()
    }, []);

    const formik = useFormik({
        initialValues: {
            CompanyId: '',
            CompanyName: '',
            SelectedUsers: []
        },
        onSubmit: values => {
            console.log('Submitted Values:', values);
            saveData(values);
        }
    });


    const saveData = async (values) => {
        const result = await fetch(`${process.env.REACT_APP_API_URL}/Users/AddPeopleToCompany`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        });
        const response = await result.json();
        console.log(response)
        if (result.ok) {
            console.log("data saved")
        }
        console.log("Saving Menu Data: ", values);


        setStatus(true);
    };


    // useEffect(() => {
    //     if (Data) {

    //     }, [Data]);



    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add People To Your Organisation"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    {/* Input for Parent Menu Name */}
                    <div className='w-full'>
                        <InputBox
                            inputId='ComapnyName'
                            inputLabel='ComapnyName'
                            inputName='Comapny Name'
                            inputValue={formik.values.CompanyName}
                            onChange={formik.handleChange}
                            type='text'
                            customcss='w-full'
                        />
                    </div>

                    <div>
                        <InputBox
                            inputId='Name'
                            inputLabel='Name'
                            inputName='Name'
                            inputValue={Data.Name}
                            type='text'
                            customcss='w-full'
                        />
                    </div>

                    {/* Dropdown for selecting menus */}
                    <div>
                        <InputWithDropDownv2
                            data={UsersList}
                            dataField='Id'
                            dataText='Name'
                            inputId='SelectedUsers'
                            inputLabel='Users'
                            inputName='SelectedUsers'
                            multiple
                            onChange={(event, newValue) => {
                                const selectedPeople = newValue.map(item => item.Id);
                                formik.setFieldValue('SelectedUsers', selectedPeople);
                            }}
                            inputValue={UsersList.filter(item => (formik.values.SelectedUsers || []).includes(item.Id))}
                        />
                    </div>

                    {formik.errors.selectedMenus && <p className="text-red-500">{formik.errors.selectedMenus}</p>}

                    {/* Save Button */}
                    <Button variant='contained' className='mt-4' id='addChannel' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditModal;
